export class FormNutrition {
  constructor(public id: string= null,
    public type: string= null,
    public date: any = null,
    public age: string= null,
    public weigh: string= null,
    public height: string= null,
    public weigh_age: string= null,
    public weigh_height: string= null,
    public circumference: string= null,
    public ruft_packets: string= null,
    public ruft_per_day: string= null,
    public formula: string= null,
    public degree_malnutrition: string= null,
    public comments: string= null,
    public mission_datas_id: string= null,
    public patients_id: string= null,
    public photo: File | null = null,
    public care_needed_id: string = null) {
  }
}
