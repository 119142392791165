import { FormBase } from './form-base';
import { ServiceUtils } from '../services/utils.service';
import { Select2OptionData } from 'ng-select2';

export abstract class FormBaseWithFieldCareNeeded extends FormBase {

    careNeededs: any;
    surgeryNeededs: any;

    abstract disableCareNeededForEntity = [];

    constructor(private serviceUtils: ServiceUtils) {
        super();
    }

    getSelect2OptionsData(dataObject) {
        let values = [];
        dataObject.forEach(element => {
            if (!this.disableCareNeededForEntity.includes(element.name)) {
                const registration = <Select2OptionData>{ "id": element.id, "text": element.name };
                values.push(registration);
            }
        })
        return values;
    }

    getNameForExternalEntity(id: any, externalEntityType: string) {
        let value = "";
        switch (externalEntityType) {
            case 'care-needed': {

                if (this.careNeededs) {
                    this.careNeededs.forEach(e => {
                        if (e.id == id) {
                            value = e.text;
                        }
                    })
                }
                break;
            }
            case 'surgery-needed': {
                if (this.surgeryNeededs) {
                    this.surgeryNeededs.forEach(e => {
                        if (e.id == id) {
                            value = e.text;
                        }
                    })
                }
                break;
            }
            default : throw new Error('Entity type is not found for this class');
        }
        return value;
    }

    loadCareNeeded() {
        this.serviceUtils.getAllCareNeeded().then((response) => {
            let allCareNeeded = response.data ? response.data.care_neededs : {};
            this.careNeededs = this.getSelect2OptionsData(allCareNeeded);
        }).catch((error) => {
            console.log(error)
        });
    }


    loadSurgeryNeeded() {
        this.serviceUtils.getAllSurgeryNeeded().then((response) => {
            let allSurgeryNeededs = response.data ? response.data.surgery_neededs : {};
            this.surgeryNeededs = this.getSelect2OptionsData(allSurgeryNeededs);
        }).catch((error) => {
            console.log(error)
        });
    }

}