import { Component, Input, OnInit } from '@angular/core';
import { UserService } from "../../services/user-service.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input() active: string;
  user: any;
  userAdmin: boolean = false;

  constructor(private userService: UserService, private route: Router) { }

  ngOnInit() {
    this.user = this.userService.getUserInfo();
    this.userAdmin = this.userService.isUserAdmin(this.user);
    console.log(this.userAdmin)
  }


  logout() {
    this.userService.logout();
    this.route.navigate(["/login"]);
  }

}
