import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { OsmConstant } from "../models/constant";
import { OSMResponse } from "../models/response";
import { FormNutrition } from "../models/nutrition";
import { formatDate } from '@angular/common';
import { FormSearchPatient } from '../models/patient';

@Injectable({
  providedIn: 'root'
})
export class NutritionService {

  constructor(private http: HttpClient) {
  }

  getAllPatientNutritions() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "patients/has-type/nutritions.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  getNutritionForPatient(patientId) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "mission-datas/patient-id/" + patientId + "/nutritions.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  saveNutrition(formNutrition: FormNutrition) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "nutritions/add.json", this.convertToFormData(formNutrition), options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  convertToFormData(formNutrition: FormNutrition) {
    let formData = new FormData();
    formData.append("id", formNutrition.id);
    formData.append("mission_datas_id", formNutrition.mission_datas_id);
    formData.append("patients_id", formNutrition.patients_id);
    formData.append("weigh", formNutrition.weigh);
    formData.append("weigh_age", formNutrition.weigh_age);
    formData.append("weigh_height", formNutrition.weigh_height);
    formData.append("height", formNutrition.height);
    formData.append("ruft_packets", formNutrition.ruft_packets);
    formData.append("ruft_per_day", formNutrition.ruft_per_day);
    formData.append("formula", formNutrition.formula);
    formData.append("type", formNutrition.type);
    formData.append("age", formNutrition.age);
    formData.append("circumference", formNutrition.circumference);
    formData.append("photo", formNutrition.photo);
    formData.append("comments", formNutrition.comments);
    formData.append("date", formNutrition.date);
    formData.append("degree_malnutrition", formNutrition.degree_malnutrition);
    formData.append("care_needed_id", formNutrition.care_needed_id);
    return formData;
  }

  printNutritionForPatient(idPatient: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "patients/export-pdf-details/" + idPatient + "/nutritions.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  getSearchUrlForPatientNutritions(key: string) {
    return OsmConstant.BASE_URL + "patients/has-type/nutritions.json?key=" + key;
  }

  getSearchUrlForPatientNutritionsForPrint(key: string, email: string, comment: string) {
    return OsmConstant.BASE_URL + "patients/has-type/nutritions.json?comment=" + comment + "&email="+ email + "&pdf=1&key=" + key;
  }

  getAdvancedSearchUrlForPrint(formSearch: FormSearchPatient, commet: string) {
    let params = "";
    let i = 1;
    Object.keys(formSearch).forEach(function (key) {
      if (formSearch[key]) {
        params = params + ((i != 0) ? "&" : "") + key + "=" + formSearch[key];
        i++;
      }
    })
    return OsmConstant.BASE_URL + "patients/has-type/nutritions.json?comment=" + commet + "&pdf=1" + params;
  }
}
