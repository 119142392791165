import { Component, Input, OnInit } from '@angular/core';
import { FormSurgery } from "../../../models/surgery";
import { SurgeryService } from "../../../services/surgery.service";
import { OsmConstant } from "../../../models/constant";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router } from "@angular/router";
import { ServiceUtils } from "../../../services/utils.service";
import { FormBase } from 'src/app/Base/form-base';
import { FormBaseWithFieldCareNeeded } from 'src/app/Base/form-base-care-needed';

@Component({
  selector: 'form-surgery',
  templateUrl: './form-surgery.component.html',
  styleUrls: ['./form-surgery.component.css']
})
export class FormSurgeryComponent extends FormBaseWithFieldCareNeeded implements OnInit {

  constructor(private surgeryService: SurgeryService, private loader: NgxUiLoaderService, private route: Router, private service: ServiceUtils) { 
    super(service);
  }

  @Input() missionDataId: string;
  @Input() surgeryForm: FormSurgery = new FormSurgery();
  @Input() patientId: string;

  disableCareNeededForEntity = ["Surgery"];

  ngOnInit() {
    this.initializeDisplayImage(this.surgeryForm.photo ? this.imageSuffix + this.surgeryForm.photo : null);
    this.loadCareNeeded();
    this.loadSurgeryNeeded();
  }

  onSave() {
    this.surgeryForm.mission_datas_id = this.missionDataId;
    this.surgeryForm.patients_id = this.patientId;
    this.loader.start();
    this.surgeryService.saveSurgery(this.surgeryForm).then((resp) => {
      this.loader.stop()
      this.toast.fire({
        icon: 'info',
        title: resp.message
      })
      setTimeout(() => {
        this.afterAction.emit(resp.data);
      }, 500);
    }).catch((error) => {
      this.loader.stop()
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
        this.toast.fire({
          icon: 'error',
          title: error.statusText ? error.statusText : error.error.message
        })
      }
    })
  }

  addPhoto(imageInBase64: any, imageIndex : number = 0): void {
    this.surgeryForm.photo = this.handleImage(imageInBase64)[imageIndex];
  }

  onDelete(): void {
    throw new Error("Method not implemented.");
  }



}
