export class FormMissionData {



  constructor(public patients_id: string = "",
              public type_mission_datas_id: string = "",
              public mission_date: any = null,
              public chart_number: string = "",
              public locations_id: string = "",
              public patient_lodgin: string = "",
              public comments: string = "",
              public id: string = null,
              public photo:  File | null = null,
              public is_close: boolean = false){
  }

}
