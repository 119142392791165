export class Patient {

  public district;
  public region;
  public pathology;
  public created;
  public age_string;
  public registration_chanel;
  

  constructor(public photo_url: string = null,
    public name: string = '',
    public firstname: string = '',
    public age: any = 0,
    public address: string = '',
    public contact1: string = '',
    public pathologies_id: string = '',
    public date_of_birth: any = null,
    public patient_advocate: string = '',
    public contact2: string = '',
    public reference1: string = '',
    public reference2: string = '',
    public districts_id: string = '',
    public regions_id: string = '',
    public registration_chanels_id: string = '',
    public patient_story: string = '',
    public sex: string = '',
    public photo: File | null = null,
    public id: string = null,
    public force: any = 0) {
  }
}


export class FormSearchPatient {
  constructor(public name: string = null,
    public firstname: string = null,
    public address: string = null,
    public pathologies_id: string = null,
    public districts_id: string = null,
    public regions_id: string = null,
    public type_mission_datas_id: string = null,
    public mission_date: any = null,
    public chart_number: string = null,
    public patient_advocate: string = null,
    public registration_chanels_id: string = null,
    public patient_lodgin: string = null,
    public start_mission_date: any = null,
    public end_mission_date: any = null,
    public bootcamp_date: any = null,
    public month1: any = null,
    public age1: any = null,
    public month2: any = null,
    public age2: any = null,
    public patient_property: string = null,
    public contact: string = null, 
    public care_needed_id: string = null,
    public surgery_needed_id: string = null,
    public start_created: any = null,
    public end_created: any = null,
    public follow_up_patient : string = null,
    public complication: string = null,
    public locations_id: string =null,
    public follow_up: string = null,
    public data_collected_during: string = null,
    public data_collected_start : any = null,
    public data_collected_end: any = null,
    public sex: string = null,
    public status: string =null) {
  }

  public getLabel(key: string) {
    let label: string;
    switch (key) {
      case 'name': label = "Name"; break;
      case 'firstname': label = "First name"; break;
      case 'address': label = "Adress"; break;
      case 'pathologies_id': label = "Pathology"; break;
      case 'districts_id': label = "District"; break;
      case 'regions_id': label = "Region"; break;
      case 'type_mission_datas_id': label = "Care Provided"; break;
      case 'mission_date': label = "Program date"; break;
      case 'chart_number': label = "Chart number"; break;
      case 'patient_advocate': label = "Patient advocate"; break;
      case 'registration_chanels_id': label = "Registration chanels"; break;
      case 'patient_lodgin': label = "Patient lodgin"; break;
      case 'start_mission_date': label = "Program date start"; break;
      case 'end_mission_date': label = "Program date end"; break;
      case 'bootcamp_date': label = "Bootcamp date"; break;
      case 'age1' : label = "Age from"; break;
      case 'month1' : label = "Month from"; break;
      case 'age2' : label = "Age to"; break;
      case 'month2' : label = "Month to"; break;
      case 'patient_property' : label = "Patient"; break;
      case 'contact' : label = "Contact"; break;
      case 'care_needed_id': label = "Aditional care needed"; break;
      case 'surgery_needed_id': label = "Aditional surgery needed"; break;
      case 'start_created': label = "Registration date (from)"; break;
      case 'end_created': label = "Registration date (To)"; break;
      case 'follow_up_patient': label = "Follow up patient "; break;
      case 'complication': label = "Complication "; break;
      case 'locations_id': label = "Locations "; break;
      case 'follow_up': label = "Follow up "; break;
      case 'data_collected_during': label = "Data collected during  "; break;
      case 'data_collected_start': label = "Data collected start date "; break;
      case 'data_collected_end': label = "Data collected end date  "; break;
      case 'sex' : label = "Gender "; break;
      case 'status' : label = "Follow up status "; break;

    }
    return label;
  }
}

export interface Pagination {
  page: number;
  prevPage: boolean;
  nextPage: boolean;
  pageCount: number;
  count: number;
}


export interface Gallery {
    id: string;
    patient_id: string;
    url_photo: string;
    path_name: string;
}