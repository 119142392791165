import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { OsmConstant } from "../models/constant";
import { OSMResponse } from "../models/response";
import { FormMissionData } from "../models/mission-data";

@Injectable({
  providedIn: 'root'
})
export class MissionDataService {

  constructor(private http: HttpClient) { }



  getFormData(patientId) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "mission-datas/add/" + patientId + ".json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  deleteFormMission(missionDataId) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.delete<OSMResponse>(OsmConstant.BASE_URL + "mission-datas/delete/" + missionDataId + ".json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  saveMissionData(formMissionData: FormMissionData) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "mission-datas/add.json", this.convertToFormData(formMissionData), options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  closeMissionData(missionDataId: string, patientId: string) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    let params = {
      is_closed: 1,
      patients_id: patientId
    }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "mission-datas/closed-folder/" + missionDataId + ".json", params, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  convertToFormData(formMission: FormMissionData) {
    let formData = new FormData();
    formData.append("patients_id", formMission.patients_id);
    formData.append("type_mission_datas_id", formMission.type_mission_datas_id);
    formData.append("mission_date", formMission.mission_date);
    formData.append("chart_number", formMission.chart_number);
    formData.append("locations_id", formMission.locations_id);
    formData.append("patient_lodgin", formMission.patient_lodgin);
    formData.append("comments", formMission.comments);
    formData.append("id", formMission.id);
    formData.append("photo", formMission.photo);
    return formData;
  }
}
