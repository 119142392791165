import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FilmCrewForm } from "../../../models/filmcrew";
import { FilmCrewService } from "../../../services/film-crew.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { OsmConstant } from "../../../models/constant";
import { Router } from "@angular/router";
import { FormBase } from 'src/app/Base/form-base';

@Component({
  selector: 'app-filmcrew-form',
  templateUrl: './filmcrew-form.component.html',
  styleUrls: ['./filmcrew-form.component.css']
})
export class FilmcrewFormComponent extends FormBase implements OnInit {


  constructor(private filmCrewService: FilmCrewService, private loader: NgxUiLoaderService, private route: Router) {
    super();
  }

  photosIndex = [0, 1, 2, 3];

  imgSuffix = OsmConstant.BASE_URL_SANS_VERSION;

  @Input() filmForm: FilmCrewForm;
  @Input() patientId: string;
  @Input() missionDataId: string;
  @Input() filmCrewFiles = [];


  ngOnInit() {

    setTimeout(() => {
      this.photosIndex.forEach(i => {
        if(this.filmCrewFiles[i]) {
          this.initializeDisplayImage(this.imgSuffix + this.filmCrewFiles[i], i);
        }
      });
    }, 200);
  }

  addPhoto(imageInBase64: any, photoIndex = 0): void {
    console.log(photoIndex);
    switch(photoIndex) {
      case 0 : this.filmForm.photo1 = this.handleImage(imageInBase64, photoIndex)[photoIndex];break;
      case 1 : this.filmForm.photo2 = this.handleImage(imageInBase64, photoIndex)[photoIndex];break;
      case 2 : this.filmForm.photo3 = this.handleImage(imageInBase64, photoIndex)[photoIndex];break;
      case 3 : this.filmForm.photo4 = this.handleImage(imageInBase64, photoIndex)[photoIndex];break;
    }
  }
  
  onDelete(): void {
    throw new Error("Method not implemented.");
  }


  onSave() {
    this.loader.start();
    this.filmForm.patients_id = this.patientId;
    this.filmForm.mission_datas_id = this.missionDataId;
    this.filmCrewService.saveFilmCrew(this.filmForm).then((resp) => {
      this.loader.stop()
      this.toast.fire({
        icon: 'info',
        title: resp.message
      })
      setTimeout(() => {
        this.afterAction.emit(resp.data);
      }, 500);
    }).catch((error) => {
      console.log(error)
      this.loader.stop()
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        this.toast.fire({
          icon: 'error',
          title: error.statusText ? error.statusText : error.error.message
        })
      }
    })
  }
}
