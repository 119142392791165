export class OsmConstant {

  public static BASE_URL = "https://osm.nero.mg/v1/";
  public static BASE_URL_SANS_VERSION = "https://osm.nero.mg/";


  // Production

  // public static BASE_URL = "https://data.osmadagascar-patient-database.org/v1/";
  // public static BASE_URL_SANS_VERSION = "https://data.osmadagascar-patient-database.org/";
  public static STATUS_FAILED = "failed";
  public static STATUS_OK = "success";
  public static TOKEN_PREFIX = "Bearer ";

  public static EXPIRED_TOKEN = "Expired token";

  public static ROLE_ADMIN_NAME = "admin";
  public static ROLE_ANONYM_NAME = "anonyme";


  public static alreadyExistsKeyWord = "already exist";

  public static NOT_FOUND= 404;
}

export enum SettingsTableSources {
  REGISTRATION_CHANNEL,
  PATHOLOGY
}
