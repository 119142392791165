import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OsmConstant } from '../models/constant';
import { OSMResponse } from '../models/response';
import { NotificationTypeEnum } from '../models/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }


  getNombreNotification() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "notifications/nombre.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  getAllNotification() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "notifications/liste.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  markNotificationAsSeen(notificationId: string){
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "notifications/set-vue/" + notificationId+ ".json", null, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  getAllNotificationByType(type: NotificationTypeEnum, page = 0) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    let url = "";
    if(page > 0) {
      url = OsmConstant.BASE_URL + "notifications/liste/" + type+ ".json?page=" + page;
    } else {
      url = OsmConstant.BASE_URL + "notifications/liste/" + type+ ".json";
    }
    
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(url, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }
}
