import { Component, OnInit, ComponentRef, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { TablePatientComponent } from '../Patient/table-patient/table-patient.component';
import { OsmConstant } from 'src/app/models/constant';
import { Pagination } from 'src/app/models/patient';
import { PatientService } from 'src/app/services/patient.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  constructor(private resolver: ComponentFactoryResolver, private patientService: PatientService, private route: Router, private loader: NgxUiLoaderService) { }


  activepage = 'category';

  tabIndexs = [1, 2, 3, 4, 5, 6, 7];

  tabTitle = [
    'Category 1',
    'Category 2',
    'Category 3',
    'Category 4',
    'Category 5',
    'Category 6',
    'Category 7'
  ]
  selected = 1;
  listPatient = [];
  pagination: Pagination;

  sourceForSearchBar = 'Category';
  urlForSearch: string;

  comment: string;

  private componentRef: ComponentRef<any>;

  @ViewChild('listPatient', { static: false, read: ViewContainerRef }) target: ViewContainerRef;

  ngOnInit() {
    setTimeout(() => {
      this.handleTabSelect(this.selected);
    }, 200);
  }

  ngOnChanges(changes: any) {
    console.log(changes);
  }


  handleTabSelect(tabIndex: number) {
    this.selected = tabIndex;
    this.showPatientList(tabIndex);
  }

  showPatientList(priority) {
    this.target.clear();
    this.loader.start();
    this.patientService.getPatientCategoryForSchedule(priority).then((resp) => {
      this.listPatient = resp.data ? resp.data.patients : [];
      this.pagination = resp.data ? resp.data.paginate ? resp.data.paginate.Patients : null : null;
      let list = this.resolver.resolveComponentFactory(TablePatientComponent);
      this.componentRef = this.target.createComponent(list);
      this.componentRef.instance.patients = this.listPatient;
      this.componentRef.instance.schedulePriority = priority;
      this.componentRef.instance.paginate = this.pagination;
      this.componentRef.instance.category = true;
      this.componentRef.instance.url = this.urlForSearch;
      this.loader.stop();
    }).catch((error) => {
      this.loader.stop();
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    })
  }

  /**
   * Handle list from search result
   * @param data
   */
  handleResult(data) {
    this.listPatient = data ? data.patients : [];
    this.pagination = data ? data.paginate ? data.paginate.Patients : null : null;
    this.target.clear();
    let list = this.resolver.resolveComponentFactory(TablePatientComponent);
    this.componentRef = this.target.createComponent(list);
    this.componentRef.instance.patients = this.listPatient;
    this.componentRef.instance.schedulePriority = this.selected;
    this.componentRef.instance.category = true;
    this.componentRef.instance.paginate = this.pagination;
    setTimeout(() => {
      this.componentRef.instance.url = this.urlForSearch;
    }, 200);

  }

  handleUrl(url) {
    this.urlForSearch = url;
  }

}
