export class User {

  public roles_ids;

  constructor(public id: number = 0,
    public username: string = '',
    public password: string = '',
    public name: string = '',
    public roles: Array<Role> = []) {
  }
}

export interface Role {
  id: string;
  nom: string;
}


export class UserForm {

  constructor(public username: string = '',
    public password: string = '',
    public confirmPassword: string = '',
    public name: string = '',
    public type_user: string = '') {
  }
}
