import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { OsmConstant } from "../models/constant";
import { OSMResponse } from "../models/response";
import { FormSurgery } from "../models/surgery";
import { FormSearchPatient } from '../models/patient';

@Injectable({
  providedIn: 'root'
})
export class SurgeryService {

  constructor(private http: HttpClient) {
  }


  getAllPatientSurgeryGlobal() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "patients/liste/surgery.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  getAllPatientSurgery() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "patients/has-type/surgeries.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  getSurgeryForPatient(patientId) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "mission-datas/patient-id/" + patientId + "/surgeries.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  saveSurgery(formSurgery: FormSurgery) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "surgeries/add.json", this.getSurgeryFormData(formSurgery), options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  //surgery in program

  getUrlForSearch(key: string) {
    return OsmConstant.BASE_URL + "patients/has-type/surgeries.json?key=" + key;
  }

  getUrlForSearchForPrint(key: string, email: string, comment: string) {
    return OsmConstant.BASE_URL + "patients/has-type/surgeries.json?pdf=1&email="+ email + "&comment=" + comment + "&key=" + key;
  }


  getAdvancedSearchUrlForPrint(formSearch: FormSearchPatient, comment: string) {
    let params = "";
    let i = 1;
    Object.keys(formSearch).forEach(function (key) {
      if (formSearch[key]) {
        params = params + ((i != 0) ? "&" : "") + key + "=" + formSearch[key];
        i++;
      }
    })
    return OsmConstant.BASE_URL + "patients/has-type/surgeries.json?pdf=1&comment=" + comment + params;
  }


  // surgery needed
  getUrlSearchForSurgeryNeeded(key: string) {
    return OsmConstant.BASE_URL + "patients/liste/surgery.json?key=" + key;
  }

  getUrlPrintForSurgeryNeeded(key: string, email: string, comment: string) {
    return OsmConstant.BASE_URL + "patients/liste/surgery.json?pdf=1&email="+email+"&comment=" + comment + "&key=" + key;
  }


  getAdvancedSearchUrlForPrintSurgeryNeeded(formSearch: FormSearchPatient, comment: string) {
    let params = "";
    let i = 1;
    Object.keys(formSearch).forEach(function (key) {
      if (formSearch[key]) {
        params = params + ((i != 0) ? "&" : "") + key + "=" + formSearch[key];
        i++;
      }
    })
    return OsmConstant.BASE_URL + "patients/liste/surgery.json?pdf=1&comment=" + comment + params;
  }


  // details

  printSurgeryForPatient(idPatient: string) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "patients/export-pdf-details/" + idPatient + "/surgeries.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }




  getSurgeryFormData(formSurger: FormSurgery) {
    let form = new FormData();
    form.append("id", formSurger.id + "");
    form.append("patients_id", formSurger.patients_id);
    form.append("mission_datas_id", formSurger.mission_datas_id);
    form.append("comments", formSurger.comments);
    form.append("complication", formSurger.complication);
    form.append("age", formSurger.age + "");
    form.append("add_surgery_needed", formSurger.add_surgery_needed);
    form.append("date", formSurger.date);
    form.append("photo", formSurger.photo);
    form.append("type", formSurger.type);
    form.append("care_needed_id", formSurger.care_needed_id);
    form.append("surgery_needed_id", formSurger.surgery_needed_id)
    return form;
  }
}
