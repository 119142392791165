import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-camera-multiple',
  templateUrl: './camera-multiple.component.html',
  styleUrls: ['./camera-multiple.component.css']
})
export class CameraMultipleComponent implements OnInit {

  constructor() { }

  @Input() id;

  displayImages = [];

  ngOnInit() {
  }

  handleImageFromComputer(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = () => {
      this.displayImages.push(reader.result);
    };
  }

}
