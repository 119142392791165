import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SplashComponent } from "./components/splash/splash.component";
import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { FormPatientComponent } from "./components/Patient/form-patient/form-patient.component";
import { SearchPatientComponent } from "./components/Patient/search-patient/search-patient.component";
import { DetailPatientComponent } from "./components/Patient/detail-patient/detail-patient.component";
import { UsersComponent } from "./components/users/users.component";
import { SurgeryListComponent } from "./components/Surgery/surgery-list/surgery-list.component";
import { SurgeryDetailComponent } from "./components/Surgery/surgery-detail/surgery-detail.component";
import { NutritionListComponent } from "./components/Nutrition/nutrition-list/nutrition-list.component";
import { NutritionDetailComponent } from "./components/Nutrition/nutrition-detail/nutrition-detail.component";
import { ScheduleComponent } from "./components/schedule/schedule.component";
import { GalleryComponent } from "./components/utils/gallery/gallery.component";
import { FilmcrewDetailComponent } from "./components/FilmCrew/filmcrew-detail/filmcrew-detail.component";
import { FilmcrewListComponent } from "./components/FilmCrew/filmcrew-list/filmcrew-list.component";
import { SettingsComponent } from './components/settings/settings.component';
import { CameraComponent } from './components/utils/camera/camera.component';
import { CategoryComponent } from './components/category/category.component';
import { NotificationDetailsComponent } from './components/notification-details/notification-details.component';
import { DetailPhotosComponent } from './components/utils/detail-photos/detail-photos.component';
import { SurgeryGlobalListComponent } from './components/Surgery/surgery-global-list/surgery-global-list.component';

const routes: Routes = [
  { path: 'splash', component: SplashComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'patient/form', component: FormPatientComponent },
  { path: 'patient/list', component: SearchPatientComponent },
  { path: 'patient/detail/:id', component: DetailPatientComponent },
  { path: 'patient/detail/photos/:id', component: DetailPhotosComponent },
  { path: 'surgery/detail/:id', component: SurgeryDetailComponent },
  { path: 'nutrition/detail/:id', component: NutritionDetailComponent },
  { path: 'film-crew/detail/:id', component: FilmcrewDetailComponent },
  { path: 'users', component: UsersComponent },
  { path: 'surgery', component: SurgeryListComponent },
  { path: 'surgery/needed-surgery', component: SurgeryGlobalListComponent },

  { path: 'nutrition', component: NutritionListComponent },
  { path: 'schedule/others', component: ScheduleComponent },
  { path: 'schedule/category', component: CategoryComponent },
  { path: 'film-crew', component: FilmcrewListComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'camera', component: CameraComponent },
  { path: 'notification/:type', component: NotificationDetailsComponent },
  { path: '', redirectTo: '/splash', pathMatch: 'full' },
]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
