import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user-service.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private userService: UserService, private route: Router,
    private notificationService : NotificationService) { }

  user: any;

  totalNotifications: number = 0;

  totalNotificationSurgery: number = 0;

  totalNotificationNutritions: number = 0;

  ngOnInit() {
    this.user = this.userService.getUserInfo();
    this.getNombreNotification();
  }


  logout() {
    this.route.navigate(["/login"]);
    this.userService.logout();
  }


  getNombreNotification(){
    this.notificationService.getNombreNotification().then((resp) => {
      this.totalNotifications = resp.data ? resp.data.notifications ? resp.data.notifications.total : 0 : 0;
      this.totalNotificationSurgery = resp.data ? resp.data.notifications ? resp.data.notifications.surgery : 0 : 0;
      this.totalNotificationNutritions = resp.data ? resp.data.notifications ? resp.data.notifications.nutrition : 0 : 0;
    })
  }

}
