import { Component, OnInit, resolveForwardRef } from '@angular/core';
import { Settings } from 'src/app/models/settings';
import { SettingsService } from 'src/app/services/settings.service';
import { OsmConstant, SettingsTableSources } from 'src/app/models/constant';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import Swal from 'sweetalert2';
import { title } from 'process';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  activepage = 'settings';
  constructor(private settingsService: SettingsService, private route: Router, private loader: NgxUiLoaderService) { }

  recentNewActivitiesSettings: Settings = new Settings("date_home");

  paginationNumberPerPageSettings: Settings = new Settings("limit_list");

  paginationNumberPerPageOptions = [15, 20, 30, 40];

  recentNewActivitiesOptions = [5, 10, 15, 20, 30];

  staticLinkForCsvTemplate = OsmConstant.BASE_URL_SANS_VERSION + "import_patient.xlsx";
  
  staticLinkForImportUrl = OsmConstant.BASE_URL + "imports/upload-file";

  importReportUrl: string = "";

  settingsForPathologies: any =  {
    title: 'Here is the list of items in the pathology category, you can add items to this list if you wish.',
    sources : SettingsTableSources.PATHOLOGY
  };


  settingsForRegistrationChannels: any = {
    title : ' Here is the list of items in the registration chanel, you can add items to this list if you wish.',
    sources: SettingsTableSources.REGISTRATION_CHANNEL
  }

  csvModels = {
    csv: File
  }

  csvName : string = "No file selected...";


  toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: false,
  });


  ngOnInit() {

    this.settingsService.loadDisplaySettings().then((data) => {
      console.log(data);
      const settings = data ? data.data ? data.data.settings : [] : [];
      if (Array.isArray(settings)) {
        settings.forEach(element => {
          if (element.name == this.recentNewActivitiesSettings.name) {
            this.recentNewActivitiesSettings.value = element.value;
          }
          if (element.name == this.paginationNumberPerPageSettings.name) {
            this.paginationNumberPerPageSettings.value = element.value;
          }
        })
      }
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    })
  }


  saveDisplaySettings() {
    this.loader.start();
    this.settingsService.saveDisplaySettings(this.recentNewActivitiesSettings).then((resp) => {
      console.log(resp);
      this.settingsService.saveDisplaySettings(this.paginationNumberPerPageSettings).then((resp) => {
        console.log(resp);
        this.loader.stop();
        this.toast.fire({
          icon: 'info',
          title: resp.message
        });
      }).catch((error) => {
        console.log(error);
      })
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    })

  }


  onUploadCsv(){
    this.loader.start();
    this.settingsService.uploadFile(this.csvModels.csv).then((resp) => {
        if(resp.data){
          this.importReportUrl = OsmConstant.BASE_URL_SANS_VERSION + resp.data.link;
        }
      
        this.toast.fire({
          icon: 'info',
          title: resp.message
        });
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    }).finally(() => {
      this.loader.stop();
    })
  }

  handleFile(file) {
    this.csvModels.csv = file[0];
    this.csvName = this.csvModels.csv.name;
  }

}
