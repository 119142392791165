import { Component, OnInit } from '@angular/core';
import { Patient, Pagination } from "../../../models/patient";
import { PatientService } from "../../../services/patient.service";
import { UserService } from "../../../services/user-service.service";
import { OsmConstant } from "../../../models/constant";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: 'app-search-patient',
  templateUrl: './search-patient.component.html',
  styleUrls: ['./search-patient.component.css']
})
export class SearchPatientComponent implements OnInit {

  activepage = 'search-patient';
  patients: Array<Patient>;
  pagination: Pagination;

  urlForSearch: string;

  sourcesForSearchBar = "patients";

  constructor(private patientService: PatientService, private userService: UserService, private route: Router, private loader: NgxUiLoaderService) {
  }

  ngOnInit() {
    this.userService.checkUser();
    this.loader.start();
    this.patientService.getAllPatient().then((resp) => {
      this.loader.stop();
      this.patients = resp.data ? resp.data.patients : {};
      this.pagination = resp.data ? resp.data.paginate ? resp.data.paginate.Patients : null : null;
    }).catch((error) => {
      this.loader.stop();
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    })
  }

  handleResult(data) {
    this.patients = data ? data.patients : [];
    this.pagination = data ? data.paginate ? data.paginate.Patients : null : null;
  }

  handleUrl(url) {
    this.urlForSearch = url;
  }

}
