import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'formatDate' })
export class DateFormaterPipe implements PipeTransform {

    constructor() {
    }

    transform(str) {
        if (str) {
            let date = new Date(str),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
        }
        return str;
    }

}