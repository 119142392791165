export class FilmCrewForm {

  constructor(public date: any = null,
              public update_by: string = "",
              public patients_story = "",
              public patients_id: string = "",
              public mission_datas_id = "",
              public photo1: File | null = null,
              public photo2: File | null = null,
              public photo3: File | null = null,
              public photo4: File | null = null,
              public id: string = null) {
  }

}
