import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Patient, Pagination } from "../../../models/patient";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { StateService } from "../../../services/state.service";
import { OsmConstant } from "../../../models/constant";
import { PatientService } from 'src/app/services/patient.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from 'src/app/services/user-service.service';

@Component({
  selector: 'table-patient',
  templateUrl: './table-patient.component.html',
  styleUrls: ['./table-patient.component.css']
})
export class TablePatientComponent implements OnInit, OnChanges {

  @Input() patients: Array<Patient>;

  @Input() paginate: Pagination;

  @Input() withOptions: Boolean = false;

  @Input() optionForSurgery: Boolean = false;

  @Input() optionForNutririon: Boolean = false;

  @Input() optionForFC: Boolean = false;

  @Input() schedulePriority: number;

  @Input() url: string;

  @Input() category : boolean = false;

  imageSuffix = OsmConstant.BASE_URL_SANS_VERSION;

  pages = [];

  current = 1;

  startPage = 1;

  pageOfPagination = 1;

  totalPageForPagination = 20;

  borderColorClass = ['table_of_patient', 'table_of_patient2', 'table_of_patient3', 'table_of_patient4', 'table_of_patient5'];

  userAdmin: boolean = true;

  constructor(private route: Router, private userService: UserService, private stateService: StateService, private patientService: PatientService, private loader: NgxUiLoaderService) { }

  ngOnInit() {
    if (!this.userService.isUserAdmin(this.userService.getUserInfo())) {
      this.userAdmin = false;
    }
  }

  ngOnChanges(changes: any) {
    if (changes.paginate && changes.paginate.currentValue) {
      this.current = 1;
      this.startPage = 1;
      this.paginate = changes.paginate.currentValue;
      this.reloadPages(1);
    }
  }

  reloadPages(pageOfpagination) {
    this.pages = [];
    // to do split pagination
    if(this.paginate.pageCount > this.totalPageForPagination ) {
      if((pageOfpagination * this.totalPageForPagination) - this.paginate.pageCount > 0 && (pageOfpagination * this.totalPageForPagination) - this.paginate.pageCount < this.totalPageForPagination){
        this.paginate.pageCount = pageOfpagination * this.totalPageForPagination -  ((pageOfpagination * this.totalPageForPagination) - this.paginate.pageCount);
      }else{
        this.paginate.pageCount = pageOfpagination * this.totalPageForPagination;
      }
    }
    for (let i = this.startPage; i <= this.paginate.pageCount; i++) {
      this.pages.push(i);
    }
  }

  update(index) {
    this.stateService.addState(this.patients[index]);
    this.route.navigate(['patient/form']);
  }


  paginateTo(page: number) {
    this.current = page;
    //next
    if(this.current > this.paginate.pageCount) {
      this.startPage = this.paginate.pageCount +1;
      this.pageOfPagination = this.pageOfPagination + 1;
    }
    //previous
    if(this.current < this.startPage) {
      this.pageOfPagination = this.pageOfPagination - 1;
      this.startPage = (this.pageOfPagination * this.totalPageForPagination)- (this.totalPageForPagination -1);
    }
    this.loader.start();
    this.patientService.getAllPatientWithPagination(this.getUrlForPagination(), page).then((data) => {
      this.patients = data.data.patients;
      this.paginate = data.data.paginate.Patients;
      this.loader.stop();
      this.reloadPages(this.pageOfPagination);
    });

  }

  getUrlForPagination() {
    if (this.url) {
      return this.url.endsWith(".json") ? this.url + "?page=" : this.url + "&page=";
    }
    else if (this.withOptions) {
      return OsmConstant.BASE_URL + "patients/index.json?page=";
    } else if (this.optionForFC) {
      return OsmConstant.BASE_URL + "patients/has-type/filme-crews.json?page=";
    } else if (this.optionForNutririon) {
      return OsmConstant.BASE_URL + "patients/has-type/nutritions.json?page=";
    } else if (this.optionForSurgery) {
      return OsmConstant.BASE_URL + "patients/has-type/surgeries.json?page=";
    } else if (this.schedulePriority && !this.category) {
      return OsmConstant.BASE_URL + "patients/priorities/" + this.schedulePriority + ".json?page=";
    } else if(this.category) {
      return OsmConstant.BASE_URL + "patients/category/" + this.schedulePriority + ".json?page=";
    }
  }


  getBorderColorForTable() {
    return this.borderColorClass[Math.floor(Math.random() * this.borderColorClass.length)];
  }

}
