import { Injectable } from '@angular/core';
import { User } from "../models/user";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { OsmConstant } from "../models/constant";
import { OSMResponse } from "../models/response";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private route: Router) {
  }

  getUserByUsernameAndPassword(user: User) {

    let userLoginFormData = {
      "username": user.username,
      "password": user.password
    };

    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "users/login.json", userLoginFormData)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  getAllUsers() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "users/index.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  addUser(users) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "users/add.json", users, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  getUserInfo() {
    return JSON.parse(localStorage.getItem("userInfo"));
  }


  checkUser() {
    if (!localStorage.getItem("token")) {
      this.route.navigate(["/login"]);
    }
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
  }


  deleteUser(userId: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "users/delete/" + userId + ".json", null, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  isUserAdmin(user: User) {
    let admin = false;
    user.roles.forEach(r => {
      if (r.nom == 'admin') {
        admin = true;
      }
    })
    return admin;
  }

}
