import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from "../../../models/user";
import { UserService } from 'src/app/services/user-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'table-users',
  templateUrl: './table-users.component.html',
  styleUrls: ['./table-users.component.css']
})
export class TableUsersComponent implements OnInit {

  constructor(private userService: UserService, private loader: NgxUiLoaderService) { }

  @Input() users: Array<User>;

  @Output() afterDelete = new EventEmitter<any>();

  user: any;
  

  ngOnInit() {
    this.user = this.userService.getUserInfo();
  }

  deleteUser(userId: string) {
    if (confirm("Delete this user ?")) {
      this.loader.start();
      this.userService.deleteUser(userId).then((data) => {
        console.log("emiting data after delete");
        this.afterDelete.emit(true);
        this.loader.stop();
      }).catch((error) => {
        console.log(error);
        this.loader.stop();
      })
      console.log("delete user " + userId);
    }

  }

}
