import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef, OnChanges } from '@angular/core';
import { PatientService } from "../../services/patient.service";
import { OsmConstant } from "../../models/constant";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router } from "@angular/router";
import { TablePatientComponent } from "../Patient/table-patient/table-patient.component";
import { Pagination } from 'src/app/models/patient';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit, OnChanges {

  constructor(private resolver: ComponentFactoryResolver, private patientService: PatientService, private route: Router, private loader: NgxUiLoaderService) { }


  activepage = 'schedule';

  tabIndexs = [1, 2, 3, 4, 5, 6];

  tabTitle = [
    'Canceled Patients',
    '9 years old Palate Patients',
    'Returned Patients (additional surgery needed ≠ 0)',
    'Return screened patients',
    'Feeding program Patients',
    'Newly registered patients'
  ]
  selected = 1;
  listPatient = [];
  pagination: Pagination;

  sourceForSearchBar = 'Schedule';
  urlForSearch: string;

  comment: string;

  private componentRef: ComponentRef<any>;

  @ViewChild('listPatient', { static: false, read: ViewContainerRef }) target: ViewContainerRef;

  ngOnInit() {
    setTimeout(() => {
      this.handleTabSelect(this.selected);
    }, 200);
  }

  ngOnChanges(changes: any) {
    console.log(changes);
  }


  handleTabSelect(tabIndex: number) {
    this.selected = tabIndex;
    this.showPatientList(tabIndex);
  }

  showPatientList(priority) {
    this.target.clear();
    this.loader.start();
    this.patientService.getPatientPriorityForSchedule(priority).then((resp) => {
      this.listPatient = resp.data ? resp.data.patients : [];
      this.pagination = resp.data ? resp.data.paginate ? resp.data.paginate.Patients : null : null;
      let list = this.resolver.resolveComponentFactory(TablePatientComponent);
      this.componentRef = this.target.createComponent(list);
      this.componentRef.instance.patients = this.listPatient;
      this.componentRef.instance.schedulePriority = priority;
      this.componentRef.instance.paginate = this.pagination;
      this.componentRef.instance.url = this.urlForSearch;
      this.loader.stop();
    }).catch((error) => {
      this.loader.stop();
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    })
  }

  /**
   * Handle list from search result
   * @param data
   */
  handleResult(data) {
    console.log("handle data ", data)
    this.listPatient = data ? data.patients : [];
    this.pagination = data ? data.paginate ? data.paginate.Patients : null : null;
    this.target.clear();
    let list = this.resolver.resolveComponentFactory(TablePatientComponent);
    this.componentRef = this.target.createComponent(list);
    this.componentRef.instance.patients = this.listPatient;
    this.componentRef.instance.schedulePriority = this.selected;
    this.componentRef.instance.paginate = this.pagination;
    setTimeout(() => {
      this.componentRef.instance.url = this.urlForSearch;
    }, 200);

  }

  handleUrl(url) {
    console.log("handle url", url)
    this.urlForSearch = url;
  }

}
