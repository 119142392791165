import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { NutritionService } from "../../../services/nutrition.service";
import { FormNutrition } from "../../../models/nutrition";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { FormNutritionComponent } from '../../utils/form-nutrition/form-nutrition.component';

@Component({
  selector: 'app-nutrition-detail',
  templateUrl: './nutrition-detail.component.html',
  styleUrls: ['./nutrition-detail.component.css']
})
export class NutritionDetailComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute, private resolver: ComponentFactoryResolver, private nutritionService: NutritionService, private loader: NgxUiLoaderService) {
  }

  patientId: string;

  activepage = 'nutrition';
  missiondatas = [];
  missionDataId: string;
  
  componentIndex: number = 0;

  isClose: boolean = false;
  id = 1;

  @ViewChild('formNutrition', { static: false, read: ViewContainerRef }) target: ViewContainerRef;
  private subs: Subscription[] = [];
  private componentRef: ComponentRef<any>;

  ngOnInit() {

    this.activeRoute.params.subscribe(params => {
      this.patientId = params["id"];
      this.loader.start();
      this.nutritionService.getNutritionForPatient(this.patientId).then((response) => {
        this.missiondatas = response.data ? response.data.mission_datas : [];
        if (this.missiondatas[0]) {
          if (this.missiondatas[0].is_closed) {
            this.isClose = this.missiondatas[0].is_closed == 1;
          }
          this.missionDataId = this.missiondatas[0].id;
          this.refreshList(this.missiondatas[0]);
        }
        this.loader.stop();
      }).catch((error) => {
        console.log(error)
        this.loader.stop();
      });
    });
    
  }


  addNutritionForm() {
    let nutritionForm = this.resolver.resolveComponentFactory(FormNutritionComponent);
    this.componentRef = this.target.createComponent(nutritionForm);
    this.componentRef.instance.patientId = this.patientId;
    this.componentRef.instance.idForPatient = this.getId(this.id);
    this.componentRef.instance.missionDataId = this.missiondatas[0].id;
    this.componentIndex++;
    this.componentRef.instance.afterAction.subscribe(e => {
      this.refreshList(e.mission_datas[0])
    })
    this.componentRef.instance.index = this.componentIndex;
    this.componentRef.instance.idFilmForPatient = this.getId(this.id);
    this.componentRef.instance.deleteSelf.subscribe(e => {
        this.target.remove(e);
        this.id--;
    });
    this.id++;
  }


  afterCloseFolder(event) {
    if (event.data) {
      this.isClose = event.data.mission_datas ? event.data.mission_datas[0].is_closed == 1 : false;
    }
  }

  getId(id: number) {
    if (id < 10) {
      return "00" + id;
    }
    if (id >= 10 && id < 100) {
      return "0" + id;
    }
    else return id;
  }

  convert(str) {
    if (str) {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    return str;
  }

  refreshList(missionData) {
    this.target.clear();
    this.id = 1;
    missionData.nutritions.forEach(e => {
      let nutritionForm = this.resolver.resolveComponentFactory(FormNutritionComponent);
      this.componentRef = this.target.createComponent(nutritionForm);
      this.componentRef.instance.patientId = this.patientId;
      this.componentRef.instance.missionDataId = this.missiondatas[0].id;
      this.componentRef.instance.idForPatient = this.getId(this.id);
      this.componentRef.instance.isClose = this.isClose;
      this.componentRef.instance.index = this.componentIndex;
      this.componentRef.instance.deleteSelf.subscribe(e => {
        this.target.remove(e);
        this.id--;
      });
      this.id++;
      this.componentRef.instance.formNutrition = new FormNutrition(
        e.id, e.type, this.convert(e.date), e.age, e.weigh, e.height, e.weigh_age,
        e.weigh_height, e.circumference, e.ruft_packets, e.ruft_per_day,
        e.formula, e.degree_malnutrition, e.comments,
        this.missiondatas[0].id, this.patientId, e.photo,
        e.care_needed_id
      );
      this.componentRef.instance.afterAction.subscribe(e => {
        this.refreshList(e.mission_datas[0])
      })
      this.componentIndex++;
    });
  }


  print() {
    //get Url
    this.loader.start();
    this.nutritionService.printNutritionForPatient(this.patientId).then((resp) => {
      let url = resp.data ? resp.data[0] : "";
      this.loader.stop()
      window.open(url);
    }).catch((error) => {
      console.log(error);
      this.loader.stop();
    });
  }

}
