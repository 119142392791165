import { Component, OnInit } from '@angular/core';
import { UserService } from "../../services/user-service.service";
import { ServiceUtils } from "../../services/utils.service";
import { OsmConstant } from "../../models/constant";
import { User, UserForm } from "../../models/user";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";


import Swal from 'sweetalert2';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  activepage = 'users';
  users: Array<User>;
  admin: Array<User>;
  simple: Array<User>;

  userModel: UserForm;

  userConnected: any;

  toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false,
  });

  constructor(private userService: UserService, private route: Router, private loader: NgxUiLoaderService) {
  }

  ngOnInit() {
    this.userConnected = this.userService.getUserInfo();

    if (!this.userService.isUserAdmin(this.userConnected)) {
      return this.route.navigate(["/home"]);
    }
    this.userModel = new UserForm();
    this.userService.checkUser();
    this.refreshList();
  }

  addUser() {
    console.log(this.userModel);
    if (this.userModel.password !== this.userModel.confirmPassword) {
      this.toast.fire({
        icon: 'error',
        title: 'Wrong password\nPlease confirm your password as well'
      })
      return false;
    }
    this.loader.start();
    this.userService.addUser(this.userModel).then((response) => {
      this.loader.stop();
      this.toast.fire({
        icon: 'info',
        title: response.message
      })
      this.refreshList();
    }).catch((error) => {
      this.loader.stop();
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    });
  }


  getUserAdmin(users: Array<User>) {
    let userByRole = [];
    users.forEach(u => {
      let admin = false;
      u.roles.forEach(r => {
        if (r.nom === OsmConstant.ROLE_ADMIN_NAME) {
          admin = true;
        }
      });
      if (admin) {
        userByRole.push(u);
      }
    })
    return userByRole;
  }

  getUserAnonym(users: Array<User>) {
    let userByRole = [];
    users.forEach(u => {
      let anonym = false;
      u.roles.forEach(r => {
        if (r.nom === OsmConstant.ROLE_ANONYM_NAME) {
          anonym = true;
        }
      });
      if (anonym) {
        userByRole.push(u);
      }
    })
    return userByRole;
  }

  refreshList() {
    this.loader.start();
    this.userService.getAllUsers().then((response) => {
      this.users = response.data ? response.data.users : [];
      this.admin = this.users ? this.getUserAdmin(this.users) : [];
      this.simple = this.users ? this.getUserAnonym(this.users) : [];
      this.loader.stop();
    }).catch((error) => {
      this.loader.stop();
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    });
  }


  handleAfterDeleteAction(event) {
    console.log("handle event after delete action " + event)
    this.refreshList();
  }

}
