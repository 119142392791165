import { Component, OnInit } from '@angular/core';
import { Patient, Pagination } from "../../../models/patient";
import { UserService } from "../../../services/user-service.service";
import { OsmConstant } from "../../../models/constant";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router } from "@angular/router";
import { NutritionService } from "../../../services/nutrition.service";

@Component({
  selector: 'app-nutrition-list',
  templateUrl: './nutrition-list.component.html',
  styleUrls: ['./nutrition-list.component.css']
})
export class NutritionListComponent implements OnInit {

  constructor(private userService: UserService, private route: Router, private loader: NgxUiLoaderService, private nutritionService: NutritionService) { }

  activepage = 'nutrition';
  patients: Array<Patient>;
  pagination: Pagination;

  sourcesForSearchBar = 'Nutritions';
  urlForSearch: string;

  ngOnInit() {
    this.userService.checkUser();
    this.loader.start();
    this.nutritionService.getAllPatientNutritions().then((resp) => {
      console.log(resp)
      this.patients = resp.data ? resp.data.patients : {};
      this.pagination = resp.data ? resp.data.paginate ? resp.data.paginate.Patients : null : null;
      this.loader.stop();
    }).catch((error) => {
      this.loader.stop();
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    })
  }

  handleResult(data) {
    console.log("handle data ", data)
    this.patients = data ? data.patients : [];
    this.pagination = data ? data.paginate ? data.paginate.Patients : null : null;
  }

  handleUrl(url) {
    this.urlForSearch = url;
  }

}
