import { Component, OnInit } from '@angular/core';
import { FilmCrewService } from "../../../services/film-crew.service";
import { Patient, Pagination } from "../../../models/patient";
import { OsmConstant } from "../../../models/constant";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router } from "@angular/router";
import { UserService } from "../../../services/user-service.service";

@Component({
  selector: 'app-filmcrew-list',
  templateUrl: './filmcrew-list.component.html',
  styleUrls: ['./filmcrew-list.component.css']
})
export class FilmcrewListComponent implements OnInit {

  activepage = 'film-crew';
  patients: Array<Patient>;

  pagination: Pagination;

  sourcesForSearchBar = 'FilmeCrews';

  urlForSearch: string;

  constructor(private userService: UserService, private route: Router, private loader: NgxUiLoaderService,
    private filmCrewService: FilmCrewService) {
  }


  ngOnInit() {
    this.userService.checkUser();
    this.loader.start();
    this.filmCrewService.getAllPatientFilmCrew().then((resp) => {
      console.log(resp)
      this.patients = resp.data ? resp.data.patients : {};
      this.pagination = resp.data ? resp.data.paginate ? resp.data.paginate.Patients : null : null;
      this.loader.stop();
    }).catch((error) => {
      this.loader.stop();
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    })
  }


  handleResult(data) {
    this.patients = data ? data.patients : [];
    this.pagination = data ? data.paginate ? data.paginate.Patients : null : null;
  }

  handleUrl(url) {
    this.urlForSearch = url;
  }

}
