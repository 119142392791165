import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FilmcrewFormComponent } from "../../utils/filmcrew-form/filmcrew-form.component";
import { FilmCrewService } from "../../../services/film-crew.service";
import { FilmCrewForm } from "../../../models/filmcrew";
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-filmcrew-detail',
  templateUrl: './filmcrew-detail.component.html',
  styleUrls: ['./filmcrew-detail.component.css']
})
export class FilmcrewDetailComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute, private resolver: ComponentFactoryResolver, private filmCrewService: FilmCrewService, private loader: NgxUiLoaderService) {
  }

  patientId: string;
  missionDatas = [];
  filmCrews = [];
  activepage = 'film-crew';

  missionDataId: string;

  isClose: boolean = false;

  componentIndex: number = 0;
  id = 1;

  @ViewChild('formFilmCrew', { static: false, read: ViewContainerRef }) target: ViewContainerRef;
  private componentRef: ComponentRef<any>;

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      this.patientId = params["id"];
      this.filmCrewService.getFilmCrewForPatient(this.patientId).then((res) => {
        this.missionDatas = res.data ? res.data.mission_datas : [];

        this.filmCrews = this.missionDatas[0] ? this.missionDatas[0].filme_crews : [];
      
        if (this.missionDatas[0] && this.missionDatas[0].is_closed) {
          this.isClose = this.missionDatas[0].is_closed == 1;
        }
        this.missionDataId = this.missionDatas[0] ? this.missionDatas[0].id : null;
        this.retrieveFormForFilmCrew(this.filmCrews);
      }).catch((error) => {
        console.log(error);
      })
    });
  }

  addFormFilmCrew() {
    let filmForm = this.resolver.resolveComponentFactory(FilmcrewFormComponent);
    this.componentRef = this.target.createComponent(filmForm);
    this.componentRef.instance.patientId = this.patientId;
    this.componentRef.instance.missionDataId = this.missionDatas[0]? this.missionDatas[0].id : null;
    this.componentRef.instance.filmForm = new FilmCrewForm();
    this.componentRef.instance.afterAction.subscribe(e => {
      this.target.clear();
      this.missionDatas = e.mission_datas;
      this.filmCrews = this.missionDatas[0] ? this.missionDatas[0].filme_crews : [];
      this.retrieveFormForFilmCrew(this.filmCrews);
    })

    this.componentRef.instance.index = this.componentIndex;
    this.componentRef.instance.idFilmForPatient = this.getId(this.id);
    this.componentRef.instance.deleteSelf.subscribe(e => {
      this.target.remove(e);
      this.id--;
    });
    this.id++;
    this.componentIndex++;
  }

  afterCloseFolder(event) {
    if (event.data) {
      this.isClose = event.data.mission_datas ? event.data.mission_datas[0].is_closed == 1 : false;
    }
  }


  getId(id: number) {
    if (id < 10) {
      return "00" + id;
    }
    if (id >= 10 && id < 100) {
      return "0" + id;
    }
    else return id;
  }

  retrieveFormForFilmCrew(filmCrew) {
    if (Array.isArray(filmCrew)) {
      this.id = 1;
      filmCrew.forEach(e => {
        let filmForm = this.resolver.resolveComponentFactory(FilmcrewFormComponent);
        this.componentRef = this.target.createComponent(filmForm);
        this.componentRef.instance.patientId = this.patientId;
        this.componentRef.instance.missionDataId = this.missionDatas[0].id;
        this.componentRef.instance.idFilmForPatient = this.getId(this.id);
        this.componentRef.instance.isClose = this.isClose;
        this.componentRef.instance.filmForm = new FilmCrewForm(this.convert(e.date), e.update_by, e.patients_story, e.patients_id
          , e.mission_datas_id, null, null, null, null, e.id);
        this.componentRef.instance.filmCrewFiles = e.photos;
        this.componentRef.instance.afterAction.subscribe(e => {
          this.target.clear();
          this.missionDatas = e.mission_datas;
          this.filmCrews = this.missionDatas[0] ? this.missionDatas[0].filme_crews : [];
          this.retrieveFormForFilmCrew(this.filmCrews);
        })
        this.componentRef.instance.index = this.componentIndex;
        this.componentRef.instance.deleteSelf.subscribe(e => {
          this.id--;
          this.target.remove(e);
        });
        this.id++;
        this.componentIndex++;
      });
    }
  }

  convert(str) {
    if (str) {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    return str;
  }


  printFilmCrew() {
    //get Url
    this.loader.start();
    this.filmCrewService.printFilmCrewForPatient(this.patientId).then((resp) => {
      console.log(resp);
      let url = resp.data ? resp.data[0] : "";
      this.loader.stop()
      console.log(url);
      window.open(url);
    });
  }


}
