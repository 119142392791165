import {Component, Input, OnInit, OnChanges, ChangeDetectorRef} from '@angular/core';
import { GalleryService } from 'src/app/services/gallery.service';
import {OsmConstant} from "../../../models/constant";

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit, OnChanges {

  constructor(private galleryService: GalleryService, private cdRef:ChangeDetectorRef) {
  }

  imgSuffix = OsmConstant.BASE_URL_SANS_VERSION;

  modalOpen : boolean = false;

  slideIndex = 0;

  @Input() images = [];

  @Input() sliceImage : boolean = false;

  @Input() sliceNumber: number = 0;

  @Input() style: string = 'classique';

  imageSliced = [];

  imageIndex = 0;

  ngOnInit() {
    if(this.sliceImage == true && this.sliceNumber <= 0) {
      throw new Error("Invalid parameters value for slice params")
    }
  }

  ngOnChanges(changes: any) {
    if(changes.images){
      this.refresh();
    }
  }

  refresh(){
    let totalImages = this.images.length;
      if(this.sliceImage == true) {
        this.imageIndex = totalImages - this.sliceNumber;
        this.imageSliced = this.images.slice(this.imageIndex, totalImages);
      } else {
        this.imageSliced = this.images;
      }
  }

  handleImage(index) {
    this.modalOpen = true;
    this.showImage(index);
  }


  slideTo(number) {
    this.showImage(this.slideIndex+=number)
  }

  showImage(index){
    if(index > (this.imageSliced.length - 1)) {
      index = 0;
    }
    if(index < 0) {
      index = (this.imageSliced.length - 1);
    }
    this.slideIndex = index;
  }


  closeModal() {
    this.modalOpen = false;
  }


  isObject(val) {
    if (val === null) { return false;}
    return ( (typeof val === 'object') );
  }

  deleteGallery(id) {
    this.galleryService.deleteGallery(id).then((resp) => {
      let galleries = resp.data ? resp.data.all_photos : [];
      this.images = galleries;
      this.refresh();
    }).catch((error) => {
      console.log(error);
    })
  }

  


  /**
   * TODO
   * Mettre un check pour verifier si images est un object
   * si true, prend le pathname
   * Comme dans le modal box
   */

}
