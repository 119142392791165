import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FilmCrewForm } from "../models/filmcrew";
import { OsmConstant } from "../models/constant";
import { OSMResponse } from "../models/response";
import { FormSearchPatient } from '../models/patient';

@Injectable({
  providedIn: 'root'
})
export class FilmCrewService {

  constructor(private http: HttpClient) {
  }

  saveFilmCrew(formFilm: FilmCrewForm) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    console.log(this.convertToFormData(formFilm));
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "filme-crews/add.json", this.convertToFormData(formFilm), options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  convertToFormData(formFilm: FilmCrewForm) {
    let formData = new FormData();
    formData.append("date", formFilm.date);
    formData.append("update_by", formFilm.update_by);
    formData.append("patients_story", formFilm.patients_story);
    formData.append("patients_id", formFilm.patients_id);
    formData.append("mission_datas_id", formFilm.mission_datas_id);
    formData.append("id", formFilm.id);
    formData.append("photo1", formFilm.photo1);
    formData.append("photo2", formFilm.photo2);
    formData.append("photo3", formFilm.photo3);
    formData.append("photo4", formFilm.photo4);
    return formData;
  }

  getFilmCrewForPatient(patientId) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "mission-datas/patient-id/" + patientId + "/filme-crews.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  getAllPatientFilmCrew() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "patients/has-type/filme-crews.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  getSearchUrlForPatientFilmCrew(key: string) {
    return OsmConstant.BASE_URL + "patients/has-type/filme-crews.json?key=" + key;
  }


  getSearchUrlForPatientFilmCrewForPrint(key: string, email: string, comment: string) {
    return OsmConstant.BASE_URL + "patients/has-type/filme-crews.json?pdf=1&email="+email+"&comment=" + comment + "&key=" + key;
  }


  getAdvancedSearchUrlForPrint(formSearch: FormSearchPatient, comment: string) {
    let params = "";
    let i = 1;
    Object.keys(formSearch).forEach(function (key) {
      if (formSearch[key]) {
        params = params + ((i != 0) ? "&" : "") + key + "=" + formSearch[key];
        i++;
      }
    })
    return OsmConstant.BASE_URL + "patients/has-type/filme-crews.json?comment=" + comment + "&pdf=1" + params;
  }



  printFilmCrewForPatient(idPatient: string) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    let url = OsmConstant.BASE_URL + "patients/export-pdf-details/" + idPatient + "/filme-crews.json";
    console.log("url for print film crew", url);
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(url, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  downloadPDF(url: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }
}
