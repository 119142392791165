import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css']
})
export class SplashComponent implements OnInit {


  POURCENTAGE: string = '%';

  progressValue: number = 1;
  newVal: number = 1;
  text: string;
  interval: any;

  constructor(private route: Router, private userService: UserService) {
  }


  ngOnInit() {
    let splashComponent = this;
    this.interval = setInterval(function () {
      splashComponent.progress();
    }, 80);
  }

  progress() {
    if (this.progressValue >= 100) {
      this.goToLogin();
      clearInterval(this.interval);
    }
    this.newVal = this.progressValue * 1 + 1;
    this.progressValue = Math.floor(this.newVal);
    this.text = this.progressValue + this.POURCENTAGE;
  }

  goToLogin() {
    if (!localStorage.getItem("token")) {
      this.route.navigate(["/login"]);
    } else {
      this.route.navigate(["/home"]);
    }

  }
}
