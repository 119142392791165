import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Select2OptionData } from "ng-select2";
import { SelectBasic } from "../../models/select";
import { ServiceUtils } from "../../services/utils.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { OsmConstant } from "../../models/constant";
import { FormSearchPatient, Patient } from "../../models/patient";
import { PatientService } from "../../services/patient.service";
import { Router } from "@angular/router";
import { FilmCrewService } from 'src/app/services/film-crew.service';
import { NutritionService } from 'src/app/services/nutrition.service';
import { SurgeryService } from 'src/app/services/surgery.service';
import Swal from 'sweetalert2';
declare var $;
@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {

  districts: Array<Select2OptionData>;
  regions: Array<Select2OptionData>;
  pathologues: Array<Select2OptionData>;
  processingConditions: Array<Select2OptionData>;
  careNeeded: Array<Select2OptionData>;
  surgeryNeeded: Array<Select2OptionData>;
  

  distrctsEntities;
  regionsEntities;
  pathologiesEntities;
  processingConditionsEntities;
  registrationChannels;
  patientLodgins;
  careNeededEntities;
  surgeryNeededEntities;

  /** 
   * Models
   * formSearch : Advanced filters
   * simpleSearchKey : Simple search
   */
  formSearch: FormSearchPatient;
  simpleSearchKey: string;


  /**
   * email for print
   */

  emailPrint: string;


  /**
   * Array to handle the result list and to be published
   */
  patients: Array<Patient>;

  /**
   * Contains the result list for simple search
   */
  @Output() simpleSearchPatient = new EventEmitter<any>();

  /**
   * Contains the result list for advanced filters
   */
  @Output() filterSearchPatient = new EventEmitter<any>();

  @Output() url = new EventEmitter<any>();
  
  /**
   * Used to determine which component call this component
   */
  @Input() sources: string;

  /**
   * Used only for schedule
   * Used for params of print URL
   */
  @Input() priority: number;

  /**
   * Used only for schedule
   * Used for params of print URL
   */
  @Input() comment: string;


  /** 
   * Some field should hide depending on sources.
   */
  show: boolean = true;

  /**
   * Determine if the result number should display or not
   * TRUE if we do advanced filter
   * FALSE for simple search
   */
  displayResultNumber: boolean = false;

  /** 
   * Number of result count
   */
  resultNumber = 0;

  /**
   * Use for displaying all filters 
   */
  labelAndValue: Array<LabelAndValue> = [];

  /**
   * Array of "Program site"
   */
  allLocations = [];

  /**
   * Determine if field should enable or not
   * 
   * If true, all field are enable except on "data collected during" section
   * 
   * If false, all field are disable except on "data collected during" section
   * 
   */
  shouldEnable: boolean = true;


  /**
   * Data for dropdown of patient property
   */
  patientPropertySearch = [
    {
      id: "new",
      text: "Newly registered"
    },
    {
      id: "already_exist",
      text: "Already came to a Program"
    },
    {
      id: "closed_mission_data",
      text: "Comprehensive care provided"
    }
  ]

  /**
   * Data for dropwdown data collected during
   * dataCollectedDuringForNutrition if sources = NUTRITION 
   * dataCollectedDuringForSurgery if sources = SURGERIES
   */
  dataCollectedDurings = [];

  dataCollectedDuringForNutrition = [
    {
      id: "Program",
      text: "Program"
    },
    {
      id: "Bootcamp",
      text: "Bootcamp"
    }, {
      id: "Weekly",
      text: "Weekly"
    }, {
      id: "Six Weeks",
      text: "Six Weeks"
    }
  ]


  dataCollectedDuringForSurgery = [
    {
      id: "1",
      text: "One week"
    },
    {
      id: "6",
      text: "Six months"
    }, {
      id: "12",
      text: "One year"
    }
  ]

  /**
   * Data for dropdown follow up
   */

  followUpPatient = [
    {
      id: "surgery",
      text: "Surgery"
    },
    {
      id: "nutrition",
      text: "Nutrition"
    },
    {
      id: "filme_crew",
      text: "Film crew"
    }
  ]


  followUpNutrition = [
    {
      id: "nutrition",
      text: "Nutrition"
    }
  ]

  followUpSurgery = [
    {
      id: "surgery",
      text: "Surgery"
    }
  ]

  followUp = [
    {
      id: "surgery",
      text: "Surgery"
    },
    {
      id: "nutrition",
      text: "Nutrition"
    }
  ];

  


  drowpdownOptionsForMultiple = {
    multiple: true
  }

  statusDropDownData = [
    {
      id: "closed",
      text: "CLOSED"
    },
    {
      id: "open",
      text: "OPEN"
    }
  ]

  toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 50000,
    timerProgressBar: false,
  });



  constructor(private loader: NgxUiLoaderService, private serviceUtils: ServiceUtils, private patientService: PatientService, private route: Router,
    private filmCrewService: FilmCrewService, private nutritionService: NutritionService, private surgeryService: SurgeryService) {

  }

  ngOnInit() {

    if (['patients', 'Schedule'].indexOf(this.sources) < 0) {
      this.show = false;
    }
    this.formSearch = new FormSearchPatient();

    this.serviceUtils.getAlldistrict().then((response) => {
      this.distrctsEntities = response.data ? response.data.districts : {};
      this.districts = this.getSelect2OptionsData(this.distrctsEntities);
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    });

    this.serviceUtils.getAllRegion().then((response) => {
      this.regionsEntities = response.data ? response.data.regions : {};
      this.regions = this.getSelect2OptionsData(this.regionsEntities);
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    });

    this.serviceUtils.getAllPathologies().then((response) => {
      this.pathologiesEntities = response.data ? response.data.pathologies : {};
      this.pathologues = this.getSelect2OptionsData(this.pathologiesEntities);
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    });

    this.serviceUtils.getAllProcessingConditions().then((response) => {
      this.processingConditionsEntities = response.data ? response.data.type_mission_datas : {};
      this.processingConditions = this.getSelect2OptionsData(this.processingConditionsEntities);
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    });

    this.serviceUtils.getAllRegistrationChannels().then((response) => {
      this.registrationChannels = response.data ? response.data.registration_chanel : {};
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    });

    this.serviceUtils.getAllPatientLodgin().then((response) => {
      this.patientLodgins = response.data ? response.data.patient_lodgin : {};
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    });


    this.serviceUtils.getAllCareNeeded().then((response) => {
      this.careNeededEntities = response.data ? response.data.care_neededs : {};
      this.careNeeded = this.getSelect2OptionsData(this.careNeededEntities);
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    });

    this.serviceUtils.getAllSurgeryNeeded().then((response) => {
      this.surgeryNeededEntities = response.data ? response.data.surgery_neededs : {};
      this.surgeryNeeded = this.getSelect2OptionsData(this.surgeryNeededEntities);
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    });


    this.serviceUtils.getAllLocations().then((resp) => {
      this.allLocations = resp.data ? resp.data.locations : [];
    }).catch((error) => {
      console.log(error);
    })


    if(this.sources == 'Nutritions') {
      this.followUp = this.followUpNutrition;
    }

    if(this.sources == 'Surgeries') {
      this.followUp = this.followUpSurgery;
    }

  }

  getSelect2OptionsData(dataObject) {
    let values = [];
    dataObject.forEach(element => {
      const registration = <Select2OptionData>{ "id": element.id, "text": element.name };
      values.push(registration);
    })
    return values;
  }


  onSearch() {
    let url = this.getUrlForSearchSimple();
    this.formSearch = new FormSearchPatient();
    this.loader.start();
    this.patientService.searchSimple(url).then((resp) => {
      this.patients = resp.data ? resp.data.patients : {};
      this.loader.stop();
      this.simpleSearchPatient.emit(resp.data)
      this.url.emit(url);
      this.displayResultNumber = true;
      this.resultNumber = resp.data.paginate.Patients.count;
    }).catch((error) => {
      this.loader.stop();
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    })
  }


  doSearch() {
    console.log(this.formSearch);
    this.simpleSearchKey = null;
    this.loader.start();
    let url = this.getUrlForSearchAdvanced();
    this.patientService.searchAdvanced(url).then((resp) => {
      this.patients = resp.data ? resp.data.patients : {};
      this.loader.stop();
      this.filterSearchPatient.emit(resp ? resp.data : null)
      this.url.emit(url);

      this.displayResultNumber = true;
      this.resultNumber = resp.data.paginate.Patients.count;
      this.getLabelAndValue();
      $('.hide-modal').modal('hide');
    }).catch((error) => {
      this.loader.stop();
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
      $('.hide-modal').modal('hide');
    })
  }

  getLabelAndValue() {
    this.labelAndValue = [];
    if (this.formSearch.name) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("name"), this.formSearch.name));
    }
    if (this.formSearch.firstname) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("firstname"), this.formSearch.firstname));
    }
    if (this.formSearch.address) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("address"), this.formSearch.address));
    }
    if (this.formSearch.mission_date) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("mission_date"), this.formSearch.mission_date));
    }
    if (this.formSearch.chart_number) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("chart_number"), this.formSearch.chart_number));
    }
    if (this.formSearch.pathologies_id) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("pathologies_id"), this.getTextValueFromList(this.pathologiesEntities, this.formSearch.pathologies_id)));
    }
    if (this.formSearch.districts_id) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("districts_id"), this.getTextValueFromList(this.distrctsEntities, this.formSearch.districts_id)));
    }
    if (this.formSearch.regions_id) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("regions_id"), this.getTextValueFromList(this.regionsEntities, this.formSearch.regions_id)));
    }
    if (this.formSearch.type_mission_datas_id) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("type_mission_datas_id"), this.getTextValueFromList(this.processingConditionsEntities, this.formSearch.type_mission_datas_id)));
    }
    if (this.formSearch.patient_advocate) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("patient_advocate"), this.formSearch.patient_advocate));
    }
    if (this.formSearch.registration_chanels_id) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("registration_chanels_id"), this.getTextValueFromList(this.registrationChannels, this.formSearch.registration_chanels_id)));
    }
    if (this.formSearch.patient_lodgin) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("patient_lodgin"), this.getTextValueFromList(this.patientLodgins, this.formSearch.patient_lodgin)));
    }
    if (this.formSearch.start_mission_date) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("start_mission_date"), this.formSearch.start_mission_date));
    }
    if (this.formSearch.end_mission_date) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("end_mission_date"), this.formSearch.end_mission_date));
    }
    if (this.formSearch.bootcamp_date) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("bootcamp_date"), this.formSearch.bootcamp_date));
    }
    if (this.formSearch.age1) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("age1"), this.formSearch.age1));
    }
    if (this.formSearch.month1) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("month1"), this.formSearch.month1));
    }
    if (this.formSearch.age2) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("age2"), this.formSearch.age2));
    }
    if (this.formSearch.month2) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("month2"), this.formSearch.month2));
    }
    if (this.formSearch.patient_property) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("patient_property"), this.findTextFromId(this.formSearch.patient_property, this.patientPropertySearch)));
    }
    if (this.formSearch.contact) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("contact"), this.formSearch.contact));
    }
    if (this.formSearch.care_needed_id) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("care_needed_id"), this.findTextFromId(this.formSearch.care_needed_id, this.careNeeded)));
    }
    if (this.formSearch.surgery_needed_id) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("surgery_needed_id"), this.findTextFromId(this.formSearch.surgery_needed_id, this.surgeryNeeded)));
    }
    if (this.formSearch.start_created) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("start_created"), this.formSearch.start_created));
    }
    if (this.formSearch.end_created) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("end_created"), this.formSearch.end_created));
    }
    if (this.formSearch.follow_up_patient) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("follow_up_patient"), this.findTextFromId(this.formSearch.follow_up_patient, this.followUpPatient)));
    }
    if (this.formSearch.complication) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("complication"), this.formSearch.complication));
    }
    if (this.formSearch.locations_id) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("locations_id"), this.findTextFromId(this.formSearch.locations_id, this.allLocations)));
    }
    if (this.formSearch.follow_up) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("follow_up"), this.formSearch.follow_up));
    }
    if (this.formSearch.data_collected_during) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("data_collected_during"), this.findTextFromId(this.formSearch.data_collected_during, this.dataCollectedDurings)));
    }
    if (this.formSearch.data_collected_start) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("data_collected_start"), this.formSearch.data_collected_start));
    }
    if (this.formSearch.data_collected_end) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("data_collected_end"), this.formSearch.data_collected_end));
    }
    if (this.formSearch.sex) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("sex"), this.formSearch.sex));
    }if (this.formSearch.status) {
      this.labelAndValue.push(new LabelAndValue(this.formSearch.getLabel("status"), this.formSearch.status));
    }
  }

  print() {    
    this.loader.start();
    let url = this.getUrlForPrint(this.emailPrint);
    let isMethodPost = this.sources == 'Schedule';
    this.patientService.getPDFUrlForListPatient(url, isMethodPost).then((resp) => {
      this.loader.stop();
      $('#modal-print').modal('hide');
      this.toast.fire({
        icon: 'success',
        title: resp.data[0]
      });
    }).catch((error) => {
      this.loader.stop();
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    })
  }

  getUrlForPrint(emailPrint : string) {
    let url = "";
    if (this.sources == 'patients') {
      if (this.simpleSearchKey != null) {
        url = OsmConstant.BASE_URL + "patients/index/pdf.json?email=" + emailPrint + "&key=" + this.simpleSearchKey;
      } else {
        console.log(Object.assign({}, this.formSearch, { email: emailPrint }));
        url = OsmConstant.BASE_URL + "patients/index/pdf.json?" + this.patientService.getSearchPatientFilterUrl(Object.assign({}, this.formSearch, { email: emailPrint }), true);
      }
    }
    if (this.sources == 'Nutritions') {
      let comment = "list of patients in nutritional follow-up";
      if (this.simpleSearchKey != null) {
        url = this.nutritionService.getSearchUrlForPatientNutritionsForPrint(this.simpleSearchKey, emailPrint, comment);
      } else if (this.formSearch) {
        url = this.nutritionService.getAdvancedSearchUrlForPrint(Object.assign({}, this.formSearch, { email: emailPrint }), comment);
      }
    }
    if (this.sources == 'Surgeries') {
      let comment = 'list of patients in surgical follow-up';
      if (this.simpleSearchKey != null) {
        url = this.surgeryService.getUrlForSearchForPrint(this.simpleSearchKey, emailPrint, comment);
      } else if (this.formSearch) {
        url = this.surgeryService.getAdvancedSearchUrlForPrint(Object.assign({}, this.formSearch, { email: emailPrint }), comment);
      }
    }
    if(this.sources == 'SurgeriesNeeded'){
      let comment = 'list of patients in surgical follow-up';
      if (this.simpleSearchKey != null) {
        url = this.surgeryService.getUrlPrintForSurgeryNeeded(this.simpleSearchKey,emailPrint, comment);
      } else if (this.formSearch) {
        url = this.surgeryService.getAdvancedSearchUrlForPrintSurgeryNeeded(Object.assign({}, this.formSearch, { email: emailPrint }), comment);
      }
    }
    if (this.sources == 'FilmeCrews') {
      let comment = 'list of patients in the film crew follow-up';
      if (this.simpleSearchKey != null) {
        url = this.filmCrewService.getSearchUrlForPatientFilmCrewForPrint(this.simpleSearchKey, emailPrint, comment);
      } else if (this.formSearch) {
        url = this.filmCrewService.getAdvancedSearchUrlForPrint(Object.assign({}, this.formSearch, { email: emailPrint }), comment);
      }
    }
    if (this.sources == 'Schedule') {
      if (this.simpleSearchKey != null) {
        url = this.patientService.getSearchUrlForScheduleForPrint(this.simpleSearchKey, emailPrint, this.priority, this.comment);
      } else if (this.formSearch) {
        url = this.patientService.getAdvancedSearchUrlForScheduleForPrint(Object.assign({}, this.formSearch, { email: emailPrint }), this.priority, this.comment);
      }
    }
    if (this.sources == 'Category') {
      if (this.simpleSearchKey != null) {
        url = this.patientService.getSearchUrlForCategoryForPrint(this.simpleSearchKey, emailPrint, this.priority, this.comment);
      } else if (this.formSearch) {
        url = this.patientService.getAdvancedSearchUrlForCategoryForPrint(Object.assign({}, this.formSearch, { email: emailPrint }), this.priority, this.comment);
      }
    }
    return url;
  }


  getTextValueFromList(list, ids) {
    if (Array.isArray(list)) {
      let value = "";
      list.forEach(e => {
        ids.forEach(element => {
          if (e.id == element) {
            value = e.name + " , " + value;
          }
        });
      })
      return value;
    }
  }

  getIdForProcessingCondition(name: string) {
    let value = null;
    this.processingConditionsEntities.forEach(element => {
      if (element.model_name == name) {
        value = element.id;
      }
    });
    return value;
  }

  findTextFromId(id: any, list: Array<any>) {
    let text = "";
    list.forEach(e => {
      if (e.id == id) {
        if (e.text) {
          text = e.text;
        } else {
          text = e.name
        }
      }
    })
    return text;
  }


  getUrlForSearchSimple() {
    if (this.sources == 'patients') {
      return this.patientService.getSearchPatientSimpleUrl(this.simpleSearchKey);
    }
    if (this.sources == 'FilmeCrews') {
      return this.filmCrewService.getSearchUrlForPatientFilmCrew(this.simpleSearchKey);
    }
    if (this.sources == 'Nutritions') {
      return this.nutritionService.getSearchUrlForPatientNutritions(this.simpleSearchKey);
    }
    if (this.sources == 'Surgeries') {
      return this.surgeryService.getUrlForSearch(this.simpleSearchKey);
    }
    if(this.sources == 'SurgeriesNeeded'){
      return this.surgeryService.getUrlSearchForSurgeryNeeded(this.simpleSearchKey);
    }
    if (this.sources == 'Schedule') {
      return this.patientService.getSearchUrlForSchedule(this.simpleSearchKey, this.priority);
    }
    if (this.sources == 'Category') {
      return this.patientService.getSearchUrlForCategory(this.simpleSearchKey, this.priority);
    }
  }

  getUrlForSearchAdvanced() {
    if (this.sources == 'Schedule') {
      return this.patientService.getAdvancedSearchUrlForSchedule(this.formSearch, this.priority);
    }
    if (this.sources == 'Category') {
      return this.patientService.getAdvancedSearchUrlForCategory(this.formSearch, this.priority);
    }
    if (this.sources == 'Nutritions') {
      return this.patientService.getAdvancedSearchUrlForNutrition(this.formSearch);
    }
    if (this.sources == 'Surgeries') {
      return this.patientService.getAdvancedSearchUrlForSurgery(this.formSearch);
    }
    if(this.sources == 'SurgeriesNeeded'){
      return this.patientService.getAdvancedSearchUrlForSurgeryNeeded(this.formSearch);
    }
    return this.patientService.getSearchPatientFilterUrl(this.formSearch);
  }


  choseType(type){
    switch(type) {
      case 'nutrition' :
        this.dataCollectedDurings = this.dataCollectedDuringForNutrition; break;
      case 'surgery' :
        this.dataCollectedDurings = this.dataCollectedDuringForSurgery; break;
    }
  }

  reset() {
    this.formSearch = new FormSearchPatient();
    this.simpleSearchKey = null;
  }


  changeGridEnable(){
    this.reset();
    this.shouldEnable = !this.shouldEnable;
  }

}


export class LabelAndValue {
  constructor(public label: string, public value: string) {
  }
}
