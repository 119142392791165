import { Component, OnInit, Input } from '@angular/core';
import { SettingsTableSources } from 'src/app/models/constant';
import { ServiceUtils } from 'src/app/services/utils.service';

@Component({
  selector: 'settings-table',
  templateUrl: './settings-table.component.html',
  styleUrls: ['./settings-table.component.css']
})
export class SettingsTableComponent implements OnInit {

  constructor(private utilsService: ServiceUtils) { }

  @Input() title: string;

  @Input() sources: SettingsTableSources;

  allObjects: Array<any> = [];

  dataForModals: any;

  formData: any = {
    name: '',
    id: ''
  }

  ngOnInit() {
    this.loadListForSources();
    this.loadDataModelForSources();
  }


  getHeading(): string {
    switch (this.sources) {
      case SettingsTableSources.PATHOLOGY: {
        return 'Pathology name';
      }
      case SettingsTableSources.REGISTRATION_CHANNEL: {
        return 'Registration Chanel';
      }
    }
  }


  loadListForSources() {
    switch (this.sources) {
      case SettingsTableSources.PATHOLOGY: {
        this.utilsService.getAllPathologies().then((data) => {
          console.log(data);
          this.allObjects = data ? data.data ? data.data.pathologies : [] : [];
        }).catch((error) => {
          console.log(error);
        });
        break;
      };
      case SettingsTableSources.REGISTRATION_CHANNEL: {
        this.utilsService.getAllRegistrationChannels().then((data) => {
          console.log(data);
          this.allObjects = data ? data.data ? data.data.registration_chanel : [] : [];
        }).catch((error) => {
          console.log(error);
        });
        break;
      }
    }
  }


  loadDataModelForSources() {
    switch (this.sources) {
      case SettingsTableSources.PATHOLOGY: {
        this.dataForModals = {
          image: '../../../assets/img/virus.svg',
          id: 'modal_pathology'
        }
        break;
      };
      case SettingsTableSources.REGISTRATION_CHANNEL: {
        this.dataForModals = {
          image: '../../../assets/img/clipboard.svg',
          id: 'modal_patient_lodging'
        }
        break;
      }
    }
  }

  onSave() {

    switch (this.sources) {
      case SettingsTableSources.PATHOLOGY: {
        this.utilsService.addPathologies(this.formData).then((resp) => {
          this.allObjects = resp.data ? resp.data.pathologies : [];
        }).catch((error) => {
          console.log(error);
        })
        break;
      };
      case SettingsTableSources.REGISTRATION_CHANNEL: {
        this.utilsService.addRegistrationChannels(this.formData).then((resp) => {
          this.allObjects = resp.data ? resp.data.registration_chanels : [];
        }).catch((error) => {
          console.log(error);
        })
        break;
      }
    }
  }


  onEdit(id, name) {
    this.formData = {
      id: id,
      name: name
    }
  }

  onDelete(id) {
    if (confirm("Sure ? ")) {

      switch (this.sources) {
        case SettingsTableSources.PATHOLOGY: {
          this.utilsService.deletePathologies(id).then((resp) => {
            this.allObjects = resp.data ? resp.data.pathologies : [];
          }).catch((error) => {
            console.log(error);
          });
          break;
        }
        case SettingsTableSources.REGISTRATION_CHANNEL: {
          this.utilsService.deleteRegistrationChannels(id).then((resp) => {
            this.allObjects = resp.data ? resp.data.registration_chanels : [];
          }).catch((error) => {
            console.log(error);
          });
          break;
        }
      }
    }
  }

  /**
   * Reset models 
   */
  addNew() : void {
    this.formData = {
      name: '',
      id: ''
    }
  }

}
