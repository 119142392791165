export interface Notification {
    id: number;
    vue: boolean;
    patient_id: string;
    type: NotificationTypeEnum;
    created: string;
}

export enum NotificationTypeEnum {
    SURGERY = 'surgery',
    NUTRITION = 'nutrition'
}
