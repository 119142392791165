import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { User } from "../../models/user";
import { UserService } from "../../services/user-service.service";
import { OsmConstant } from "../../models/constant";
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  userFromForm = new User();
  userFromDb: User;
  error: boolean = false;
  errorMessage: string = "";

  constructor(private userService: UserService, private route: Router, private loader: NgxUiLoaderService) {
  }

  ngOnInit() {
  }

  doLogin() {
    this.loader.start();
    this.userService.getUserByUsernameAndPassword(this.userFromForm).then((response) => {
      this.loader.stop();
      console.log(response);
      if (response.status === OsmConstant.STATUS_OK && response.data) {
        console.log(response.data)
        this.userFromDb = response.data.user;
        this.error = false;
        this.errorMessage = response.message;
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userInfo", JSON.stringify(this.userFromDb));
        this.route.navigate(['/home']);
      } else {
        this.error = true;
        this.errorMessage = response.message;
        console.log(response.message);
      }
    }).catch((error) => {
      this.loader.stop()
      console.log(error);
    });
  }

}
