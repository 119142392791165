import {
  AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormMissionComponent } from "../../utils/form-mission/form-mission.component";
import { UserService } from "../../../services/user-service.service";
import { PatientService } from "../../../services/patient.service";
import { OsmConstant } from "../../../models/constant";
import { FormMissionData } from "../../../models/mission-data";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { GalleryService } from 'src/app/services/gallery.service';
import { Gallery } from 'src/app/models/patient';
import Swal from 'sweetalert2';
import { NOTFOUND } from 'dns';
declare var $;

@Component({
  selector: 'app-detail-patient',
  templateUrl: './detail-patient.component.html',
  styleUrls: ['./detail-patient.component.css']
})
export class DetailPatientComponent implements OnInit {

  patientId: string;
  detailPatient: any;
  shouldClosedButtonDisplay: boolean = false;

  isClosed: boolean = false;

  activepage = 'search-patient';

  componentIndex: number = 0;

  user: any;
  userAdmin: boolean = false;

  galleries : Array<Gallery> = [];

  images = [];

  id = 1;

  files = [];

  btnAddPhotoShow = true;

  filesInput = [];

  toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 50000,
    timerProgressBar: false,
  });

  @ViewChild('formMission', { static: false, read: ViewContainerRef }) target: ViewContainerRef;
  private componentRef: ComponentRef<any>;

  constructor(private activeRoute: ActivatedRoute, private resolver: ComponentFactoryResolver,
    private userService: UserService, private patientService: PatientService, 
    private route: Router, private loader: NgxUiLoaderService,
    private galleryService : GalleryService) {
  }

  ngOnInit() {
    this.userService.checkUser();
    this.activeRoute.params.subscribe(params => {
      this.patientId = params["id"];
      this.loader.start();
      this.patientService.viewPatientDetails(this.patientId).then((resp) => {
        this.detailPatient = resp.data ? resp.data.patient : {};
        if (this.detailPatient.mission_datas) {
          this.isClosed = this.detailPatient.is_closed == 1;
          this.refreshList(this.detailPatient.mission_datas);
        }
        this.loader.stop();
      }).catch((error) => {
        this.loader.stop();
        if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
          this.route.navigate(["/login"]);
        }
        else if ((error.error) && error.error.code === OsmConstant.NOT_FOUND) {
          this.toast.fire({
            icon: 'error',
            title: error.error.message 
          });
          this.route.navigate(["/patient/list"]);
        }
      })

      // get all galleries

      this.galleryService.getAllGalleryForPatient(this.patientId).then((resp) => {
        console.log("gallery", resp);
        this.galleries = resp.data ? resp.data.all_photos : [];
        // this.images = this.galleries.map(item => {
        //   return item.path_name;
        // });
        this.images = this.galleries;
      }).catch((error) => {
        console.log(error);
      })

    });

    this.user = this.userService.getUserInfo();
    this.userAdmin = this.userService.isUserAdmin(this.user);
  }

  getIdMissionForPatient(id: number) {
    if (id < 10) {
      return "00" + id;
    }
    if (id >= 10 && id < 100) {
      return "0" + id;
    }
    else return id;
  }

  addForm() {
    let missionForm = this.resolver.resolveComponentFactory(FormMissionComponent);
    this.componentRef = this.target.createComponent(missionForm);
    this.componentRef.instance.patientId = this.patientId;
    this.componentRef.instance.formData = new FormMissionData();
    this.componentRef.instance.afterAction.subscribe(e => {
      console.log("refreshing list : handle after action", e);
      this.refreshList(e.mission_datas)
    })
    this.componentRef.instance.index = this.componentIndex;
    this.componentRef.instance.idForPatient = this.getIdMissionForPatient(this.id);
    this.componentRef.instance.deleteSelf.subscribe(e => {
      this.id--;
      this.target.remove(e);
    });
    this.id++;
    this.componentIndex++;
  }

  convert(str) {
    if (str) {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    return str;
  }


  afterCloseFolder(event) {
    console.log("handle data ", event);
    if (event.data.patient) {
      this.isClosed = event.data.patient.is_closed;
    }
  }

  refreshList(missionDatas) {
    this.id = 1;
    this.target.clear();
    missionDatas.forEach(e => {
      let missionForm = this.resolver.resolveComponentFactory(FormMissionComponent);
      this.componentRef = this.target.createComponent(missionForm);
      this.componentRef.instance.patientId = this.patientId;
      this.componentRef.instance.idForPatient = this.getIdMissionForPatient(this.id);
      this.componentRef.instance.isClose = this.isClosed;
      this.componentRef.instance.formData = new FormMissionData(e.patients_id, e.type_mission_datas_id, this.convert(e.mission_date), e.chart_number, e.locations_id, e.patient_lodgin, e.comments, e.id, e.photo);
      this.componentRef.instance.afterAction.subscribe(e => {
        console.log("refreshing list : handle after action", e);
        this.refreshList(e.mission_datas)
      })
      this.componentRef.instance.index = this.componentIndex;
      this.componentRef.instance.idForPatient = this.getIdMissionForPatient(this.id);
      this.componentRef.instance.deleteSelf.subscribe(e => {
        this.target.remove(e);
        this.id--;
      });
      this.id++;
      this.componentIndex++;
    });
  }


  print() {
    //get Url
    this.loader.start();
    this.patientService.printPatient(this.patientId).then((resp) => {
      console.log(resp);
      let url = resp.data ? resp.data[0] : "";
      this.loader.stop()
      console.log(url);
      window.open(url);
    }).catch((error) => {
      console.log(error);
      this.loader.stop();
    });
  }


  goToDetailsPhoto() {
    return this.route.navigate(['/patient/detail/photos', this.patientId]);
  }



  handleImageFromComputer(files) {
    this.btnAddPhotoShow = false;
    this.files = files;
    for (var index = 0, len = files.length; index < len; ++index) {
      const reader = new FileReader();
      reader.readAsDataURL(files[index]);
      reader.onload = () => {
        this.images.push(reader.result);
        $('#modalPreview').modal('show');
      };
    }
  }


  cancel() {
    this.images = [];
    this.filesInput = [];
    $('#modalPreview').modal('hide');
  }

  clearImage() {
    this.images = [];
    this.filesInput = [];
    this.btnAddPhotoShow = true;
  }


  uploadFiles() {
    this.loader.start();
    console.log(this.files);
    this.galleryService.saveFilmCrew(this.patientId, this.files).then((resp) => {
      let galleries = resp.data ? resp.data.all_photos : [];
        // this.images = galleries.map(item => {
        //   return item.path_name;
        // });
      this.images = galleries;
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      $('#modalPreview').modal('hide');
      this.loader.stop();
    });
  }


}
