import { Injectable } from '@angular/core';
import { Patient } from "../models/patient";
import { OsmConstant } from "../models/constant";
import { OSMResponse } from "../models/response";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private http: HttpClient) {
  }

  savePatient(patient) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "patients/add.json", this.getFormData(patient), options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  editPatient(patient) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "patients/edit/" + patient.id + ".json", this.getFormData(patient), options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  deletePatient(idPatient) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.delete<OSMResponse>(OsmConstant.BASE_URL + "patients/delete/" + idPatient + ".json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  

  closeDossierForPatient(patientId) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    let params = {
      "is_closed": 1
    };
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "patients/edit/" + patientId + ".json", params, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }



  getAllPatient() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "patients/index.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  getAllPatientWithPagination(url: string, page: number) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(url + page, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  searchSimple(url: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(url, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  getSearchPatientSimpleUrl(key: string) {
    return OsmConstant.BASE_URL + "patients/index.json?key=" + key;
  }

  searchAdvanced(url: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }

    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(url, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  getSearchPatientFilterUrl(filter, paramsOnly: boolean = false) {
    let params = "";
    let i = 0;
    Object.keys(filter).forEach(function (key) {
      if (filter[key]) {
        params = params + ((i != 0) ? "&" : "") + key + "=" + filter[key];
        i++;
      }
    })
    return paramsOnly ? params : OsmConstant.BASE_URL + "patients/index.json?" + params;
  }


  getPDFUrlForListPatient(url: string, isMethodPost: boolean = false) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      if(isMethodPost == false) {
        this.http.get<OSMResponse>(url, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
      } else {
        this.http.post<OSMResponse>(url, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
      }
    });
  }

  downloadPDF(url) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }

  viewPatientDetails(patientId: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "patients/view/" + patientId + ".json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  getPatientPriorityForSchedule(priority) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "patients/priorities/" + priority + ".json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  getPatientCategoryForSchedule(category: number) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "patients/category/" + category + ".json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  getSearchUrlForSchedule(key: string, priority: number) {
    return OsmConstant.BASE_URL + "patients/priorities/" + priority + ".json?key=" + key;
  }

  getSearchUrlForCategory(key: string, priority: number) {
    return OsmConstant.BASE_URL + "patients/category/" + priority + ".json?key=" + key;
  }

  getSearchUrlForScheduleForPrint(key: string, email: string, priority: number, comment: string) {
    if (comment) {
      return OsmConstant.BASE_URL + "patients/priorities/" + priority + ".json?pdf=1&email="+email+"&comment=" + comment + "&key=" + key;
    } else return OsmConstant.BASE_URL + "patients/priorities/" + priority + ".json?pdf=1&email="+email+"&key=" + key;
  }

  getSearchUrlForCategoryForPrint(key: string, email: string, priority: number, comment: string) {
    if (comment) {
      return OsmConstant.BASE_URL + "patients/category/" + priority + ".json?pdf=1&email="+email+"&comment=" + comment + "&key=" + key;
    } else return OsmConstant.BASE_URL + "patients/category/" + priority + ".json?pdf=1&email="+email+"&key=" + key;
  }

  getAdvancedSearchUrlForSchedule(filter, priority, paramsOnly: boolean = false) {
    let params = "";
    let i = 0;
    Object.keys(filter).forEach(function (key) {
      if (filter[key]) {
        params = params + ((i != 0) ? "&" : "") + key + "=" + filter[key];
        i++;
      }
    })
    return paramsOnly ? params : OsmConstant.BASE_URL + "patients/priorities/" + priority + ".json?" + params;
  }

  getAdvancedSearchUrlForCategory(filter, priority, paramsOnly: boolean = false) {
    let params = "";
    let i = 0;
    Object.keys(filter).forEach(function (key) {
      if (filter[key]) {
        params = params + ((i != 0) ? "&" : "") + key + "=" + filter[key];
        i++;
      }
    })
    return paramsOnly ? params : OsmConstant.BASE_URL + "patients/category/" + priority + ".json?" + params;
  }

  getAdvancedSearchUrlForNutrition(filter, paramsOnly: boolean = false) {
    let params = "";
    let i = 0;
    Object.keys(filter).forEach(function (key) {
      if (filter[key]) {
        params = params + ((i != 0) ? "&" : "") + key + "=" + filter[key];
        i++;
      }
    })
    return paramsOnly ? params : OsmConstant.BASE_URL + "patients/has-type/nutritions.json?" + params;
  }

  getAdvancedSearchUrlForSurgery(filter, paramsOnly: boolean = false) {
    let params = "";
    let i = 0;
    Object.keys(filter).forEach(function (key) {
      if (filter[key]) {
        params = params + ((i != 0) ? "&" : "") + key + "=" + filter[key];
        i++;
      }
    })
    return paramsOnly ? params : OsmConstant.BASE_URL + "patients/has-type/surgeries.json?" + params;
  }

  getAdvancedSearchUrlForSurgeryNeeded(filter, paramsOnly: boolean = false) {
    let params = "";
    let i = 0;
    Object.keys(filter).forEach(function (key) {
      if (filter[key]) {
        params = params + ((i != 0) ? "&" : "") + key + "=" + filter[key];
        i++;
      }
    })
    return paramsOnly ? params : OsmConstant.BASE_URL + "patients/liste/surgery.json?" + params;
  }

  getAdvancedSearchUrlForScheduleForPrint(filter, priority, comment: string, paramsOnly: boolean = false) {
    let params = "";
    let i = 1;
    Object.keys(filter).forEach(function (key) {
      if (filter[key]) {
        params = params + ((i != 0) ? "&" : "") + key + "=" + filter[key];
        i++;
      }
    })
    if (comment) {
      return paramsOnly ? params : OsmConstant.BASE_URL + "patients/priorities/" + priority + ".json?pdf=1&comment=" + comment + params;
    } else return OsmConstant.BASE_URL + "patients/priorities/" + priority + ".json?pdf=1" + params
  }

  getAdvancedSearchUrlForCategoryForPrint(filter, priority, comment: string, paramsOnly: boolean = false) {
    let params = "";
    let i = 1;
    Object.keys(filter).forEach(function (key) {
      if (filter[key]) {
        params = params + ((i != 0) ? "&" : "") + key + "=" + filter[key];
        i++;
      }
    })
    if (comment) {
      return paramsOnly ? params : OsmConstant.BASE_URL + "patients/category/" + priority + ".json?pdf=1&comment=" + comment + params;
    } else return OsmConstant.BASE_URL + "patients/category/" + priority + ".json?pdf=1" + params
  }

  printPatient(idPatient: string) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "patients/export-pdf-details/" + idPatient + ".json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  getFormData(patient: Patient) {
    let formData = new FormData();
    formData.append("name", patient.name);
    formData.append("firstname", patient.firstname);
    formData.append("age", patient.age);
    formData.append("address", patient.address);
    formData.append("contact1", patient.contact1);
    formData.append("contact2", patient.contact2);
    formData.append("pathologies_id", patient.pathologies_id);
    formData.append("date_of_birth", patient.date_of_birth);
    formData.append("patient_advocate", patient.patient_advocate);
    formData.append("reference1", patient.reference1);
    formData.append("reference2", patient.reference2);
    formData.append("districts_id", patient.districts_id);
    formData.append("regions_id", patient.regions_id);
    formData.append("registration_chanels_id", patient.registration_chanels_id);
    formData.append("patient_story", patient.patient_story);
    formData.append("sex", patient.sex);
    formData.append("photo", patient.photo);
    formData.append("force", patient.force);
    return formData;
  }
}
