import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { OsmConstant } from '../models/constant';
import { OSMResponse } from '../models/response';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private http: HttpClient) { }


  getAllGalleryForPatient(patientId: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "patients-photos/view/" + patientId + ".json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  saveFilmCrew(patientId: string, photos: any) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "patients-photos/add.json", this.convertToFormData(patientId, Array.from(photos)), options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  convertToFormData(patientId: string, photos: any) {
    console.log("photos", photos)
    let formData = new FormData();
    formData.append("patient_id", patientId);
    photos.forEach(element => {
      formData.append("photos[]", element);  
    });
    
    return formData;
  }

  deleteGallery(galleryId) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.delete<OSMResponse>(OsmConstant.BASE_URL + "patients-photos/delete/" + galleryId + ".json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


}
