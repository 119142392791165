import {Component, HostListener} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{

  @HostListener("window:resize", [])
  public onResize() {
    this.detectScreenSize();
  }
  
  public ngAfterViewInit() {
      this.detectScreenSize();
  }
  
  private detectScreenSize() {
      const height = window.innerHeight;
      const width = window.innerWidth;
      //console.log('height=', height, 'width=', width);
      if(width < 1024) {
        alert('For a better display, please use a screen greater than 1024px');
      }
  }
}
