import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from "../../../models/user";
import { UserService } from "../../../services/user-service.service";
import { OsmConstant } from "../../../models/constant";
import { PatientService } from "../../../services/patient.service";
import { MissionDataService } from 'src/app/services/mission-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-close-folder',
  templateUrl: './close-folder.component.html',
  styleUrls: ['./close-folder.component.css']
})
export class CloseFolderComponent implements OnInit {

  formUser = new User();
  user: any;
  error = false;

  operationDone: boolean = false;

  @Input() patientId: string;

  @Input() missionDataId: string;

  @Output() afterCloseFolder = new EventEmitter<any>();

  constructor(private userService: UserService, private patientService: PatientService, private missionDataService: MissionDataService, private loader: NgxUiLoaderService) { }

  ngOnInit() {
    console.log(this.missionDataId, this.patientId)
    this.user = this.userService.getUserInfo();
    if (this.user) {
      this.formUser.username = this.user.username;
    }
  }

  doLogin() {
    console.log(this.missionDataId, this.patientId);
    this.loader.start();
    this.userService.getUserByUsernameAndPassword(this.formUser).then((response) => {
      console.log(response);
      if (response.status === OsmConstant.STATUS_OK && response.data) {
        this.error = false;
        // do Close dossier here
        if (this.missionDataId && this.patientId) {
          this.missionDataService.closeMissionData(this.missionDataId, this.patientId).then((response) => {
            console.log(response);
            this.afterCloseFolder.emit(response);
            this.loader.stop();
            this.operationDone = true;
            
          }).catch((error) => {
            console.log(error);
            this.loader.stop();
          })
        }
        else if (this.patientId) {
          this.patientService.closeDossierForPatient(this.patientId).then((response) => {
            console.log(response);
            this.afterCloseFolder.emit(response)
            this.loader.stop();
            this.operationDone = true;
          }).catch((error) => {
            console.log(error);
            this.loader.stop();
          })
        }
      } else {
        this.error = true;
        this.loader.stop();
      }
    }).catch((error) => {
      console.log(error);
      this.loader.stop();
    });
  }

}
