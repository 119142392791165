import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OsmConstant } from '../models/constant';
import { OSMResponse } from '../models/response';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  saveDisplaySettings(settingsDisplay) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "settings/add.json",
        settingsDisplay, options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }

  loadDisplaySettings(){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.get<OSMResponse>(OsmConstant.BASE_URL + "settings/index.json", options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });
  }


  uploadFile(data: any) {
    let headers = new HttpHeaders({
      'Authorization': OsmConstant.TOKEN_PREFIX + localStorage.getItem("token")
    });
    let options = { headers: headers }
    return new Promise<OSMResponse>((resolve, reject) => {
      this.http.post<OSMResponse>(OsmConstant.BASE_URL + "patients/upload-csv.json",
      this.getFormDataForCSV(data), options)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        })
    });

  }

  getFormDataForCSV(data) {
    let formData = new FormData();
    formData.append("csv", data);
    return formData;
  }

}
