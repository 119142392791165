import Swal from 'sweetalert2';
import { OsmConstant } from '../models/constant';
import { Output, EventEmitter, Input } from '@angular/core';

export abstract class FormBase {

    public displayImage = [];

    public accordionToggel: boolean = false;

    public formDataEdit: boolean  = true;

    @Output() deleteSelf: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterAction = new EventEmitter<any>();

    @Input() idForPatient: string;
    @Input() isClose: boolean = false;
    @Input() index: number;

    imageSuffix: string = OsmConstant.BASE_URL_SANS_VERSION;

    photos: Array<File | null> = [];

    toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 10000,
        timerProgressBar: false,
    });

    handleImage(imageByte, imageIndex: number = 0): Array<File | null> {
        this.displayImage[imageIndex] = imageByte;
        this.photos[imageIndex] =  this.getPhoto(imageByte);
        return this.photos;
    }

    getPhoto(e): File | null {
        const imageName = 'name.png';
        const imageBlob = this.dataURItoBlob(e);
        return new File([imageBlob], imageName, { type: 'image/png' });
    }

    dataURItoBlob(dataURI): Blob | null {
        const byteString = window.atob(dataURI.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });
        return blob;
    }

    handleAccordion(): void {
        this.accordionToggel = !this.accordionToggel;
    }

    abstract addPhoto(imageInBase64: any, photoIndex : number): void;

    abstract onSave(): void;

    abstract onDelete(): void;

    initializeDisplayImage(photoUrl: string, index: number = 0): void {
        this.displayImage[index] = photoUrl;
    }

    setFormDataEdit(): void {
        this.formDataEdit = false
    }

}