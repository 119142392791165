export class FormSurgery {
  constructor(public type: any = null,
    public date: any = null,
    public age: number = 0,
    public complication: string = null,
    public add_surgery_needed: string = null,
    public comments: string = null,
    public mission_datas_id: string = null,
    public id: number = 0,
    public patients_id: string = null,
    public photo: File | null = null,
    public care_needed_id: string = null,
    public surgery_needed_id: string = null) {

  }
}
