import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FormSurgeryComponent } from "../../utils/form-surgery/form-surgery.component";
import { SurgeryService } from "../../../services/surgery.service";
import { SurgeryTabsComponent } from "../../utils/surgery-tabs/surgery-tabs.component";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-surgery-detail',
  templateUrl: './surgery-detail.component.html',
  styleUrls: ['./surgery-detail.component.css']
})
export class SurgeryDetailComponent implements OnInit {

  patientId: string;

  activepage = 'surgery';

  missiondatas: any = [];

  isClose: boolean = false;

  @ViewChild(SurgeryTabsComponent, { static: false }) tabs: SurgeryTabsComponent;

  constructor(private userService: UserService, private activeRoute: ActivatedRoute, private resolver: ComponentFactoryResolver, private surgeryService: SurgeryService, private loader: NgxUiLoaderService) {
  }

  ngOnInit() {
    this.userService.checkUser();
    this.activeRoute.params.subscribe(params => {
      this.patientId = params["id"];

      this.surgeryService.getSurgeryForPatient(this.patientId).then((response) => {
        this.missiondatas = response.data ? response.data.mission_datas : [];
        this.missiondatas = this.missiondatas.filter(function (el) {
          return el.canceled_id == null;
        });
      }).catch((error) => {
        console.log(error)
      });

    });


  }


  addFormSurgery() {
    this.tabs.addSurgeryForm();
  }

  print() {
    //get Url
    this.loader.start();
    this.surgeryService.printSurgeryForPatient(this.patientId).then((resp) => {
      console.log(resp);
      let url = resp.data ? resp.data[0] : "";
      this.loader.stop()
      console.log(url);
      window.open(url);
    }).catch((error) => {
      console.log(error);
      this.loader.stop();
    });
  }


  handleIsClose(e) {
    this.isClose = e;
  }

}
