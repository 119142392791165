import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Notification, NotificationTypeEnum } from 'src/app/models/notification';
import { Pagination } from 'src/app/models/patient';

@Component({
  selector: 'app-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.css']
})
export class NotificationDetailsComponent implements OnInit {

  constructor(private notificationService: NotificationService, private activeRoute: ActivatedRoute, private route: Router) { }


  notifications : Array<Notification> = [];
  notificationType: NotificationTypeEnum;
  notificationsFiltered:  Array<Notification> = [];

  paginate: Pagination;
  pages = [];
  current = 1;

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      this.notificationType = params["type"];
      this.loadAllNotifications();
    });
    
  }


  loadAllNotifications() {
    this.pages = [];
    this.notificationService.getAllNotificationByType(this.notificationType).then((resp) => {
      this.notifications = resp.data ? resp.data.notifications : [];
      this.paginate = resp.data.paginate.Notifications;
      console.log(this.paginate);
      this.current = 1;
      for (let i = 1; i <= this.paginate.pageCount; i++) {
        this.pages.push(i);
      }
      // this.filterNotificationsByType(this.notificationType);
    }).catch((err) => {
      console.log(err);
    })
  }


  // filterNotificationsByType(type: NotificationTypeEnum) {
  //   if(this.notifications) {
  //     this.notificationsFiltered = this.notifications.filter(function(notif) {
  //       return notif.type == type;
  //     });
  //   }
  // }

  paginateTo(page: number) {
    this.current = page;
    this.notificationService.getAllNotificationByType(this.notificationType, page).then((resp) => {
      this.notifications = resp.data ? resp.data.notifications : [];
      this.paginate = resp.data.paginate.Notifications;
    });

  }

  openNotification(id: string, patientId: string) {
    this.notificationService.markNotificationAsSeen(id).then((resp) => {
      console.log(resp)
    }).catch((error) => {
      console.log(error);
    });
    if(this.notificationType.toString() == NotificationTypeEnum.SURGERY.toString()) {
      return this.route.navigate(['/surgery/detail/', patientId])
    }
    if(this.notificationType.toString() == NotificationTypeEnum.NUTRITION.toString()) {
      return this.route.navigate(['/nutrition/detail/', patientId])
    }
  }

}
