import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private dataSource = new BehaviorSubject<any>(null);

  data = this.dataSource.asObservable();

  constructor() { }

  addState(data) {
    this.dataSource.next(data);
  }

  removeState(){
    this.dataSource.next(null);
  }
}
