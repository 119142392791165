import {
  AfterContentChecked, AfterViewChecked, AfterViewInit,
  Component, ComponentFactoryResolver, ComponentRef, Input, NgZone, OnInit, ViewChild,
  ViewContainerRef,
  Output,
  EventEmitter
} from '@angular/core';
import { FormSurgeryComponent } from "../form-surgery/form-surgery.component";
import { FormSurgery } from "../../../models/surgery";
import { ServiceUtils } from "../../../services/utils.service";
import { SurgeryService } from "../../../services/surgery.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-surgery-tabs',
  templateUrl: './surgery-tabs.component.html',
  styleUrls: ['./surgery-tabs.component.css']
})
export class SurgeryTabsComponent implements OnInit, AfterViewInit {

  constructor(private resolver: ComponentFactoryResolver, private serviceUtils: ServiceUtils, private surgeryService: SurgeryService, private router: Router) {
  }

  @Input() missionDatas: any = [];

  @Input() patientId: string;


  @Output() isClosed = new EventEmitter<any>();

  missionDataId: string;

  isClose: boolean = false;

  @ViewChild('formSurgery', { static: false, read: ViewContainerRef }) target: ViewContainerRef;

  selected: number;

  nb: number = 0;
  id = 1;
  componentIndex = 0;

  private componentRef: ComponentRef<any>;

  ngOnInit() {
    this.selected = this.missionDatas.length;
    setTimeout(() => {

      if (this.missionDatas[this.selected - 1].is_closed) {
        this.isClose = this.missionDatas[this.selected - 1].is_closed == 1;
      }
      this.isClosed.emit(this.isClose);
      this.missionDataId = this.missionDatas[this.selected - 1].id;
      this.loadSurgerySuiviForTabs(this.selected);
    }, 200);
  }

  ngAfterViewInit() {
    // console.log("ngAfterViewInit")
  }


  handleTabSelect(tabInde: number) {
    this.isClose = false;
    this.selected = tabInde + 1;
    setTimeout(() => {

      if (this.missionDatas[this.selected - 1].is_closed) {
        this.isClose = this.missionDatas[this.selected - 1].is_closed == 1;
      }
      this.isClosed.emit(this.isClose);
      this.missionDataId = this.missionDatas[this.selected - 1].id;
      this.loadSurgerySuiviForTabs(this.selected);
    }, 200);
  }

  addSurgeryForm() {
    let form = this.resolver.resolveComponentFactory(FormSurgeryComponent);
    this.componentRef = this.target.createComponent(form);
    this.componentRef.instance.missionDataId = this.missionDatas[this.selected - 1].id;
    this.componentRef.instance.patientId = this.patientId;
    this.componentRef.instance.afterAction.subscribe(e => {
      this.missionDatas = e.mission_datas;
      this.missionDatas = this.missionDatas.filter(function (el) {
        return el.canceled_id == null;
      });
      this.handleTabSelect(this.selected - 1);
    })
    this.componentRef.instance.index = this.componentIndex;
    this.componentIndex++;
    this.componentRef.instance.idForPatient = this.getId(this.id);
    this.componentRef.instance.deleteSelf.subscribe(e => {
      this.target.remove(e);
      this.id--;
    });
    this.id++;
    this.componentIndex++;
  }

  loadSurgerySuiviForTabs(tabIndex: number) {
    this.target.clear();
    this.id = 1;
    if (this.missionDatas[tabIndex - 1].surgeries) {
      this.missionDatas[tabIndex - 1].surgeries.forEach(e => {
        let form = this.resolver.resolveComponentFactory(FormSurgeryComponent);
        this.componentRef = this.target.createComponent(form);
        this.componentRef.instance.missionDataId = this.missionDatas[this.selected - 1].id;
        this.componentRef.instance.patientId = this.patientId;
        this.componentRef.instance.surgeryForm = this.createFormSurgery(e);
        this.componentRef.instance.idForPatient = this.getId(this.id); this.id++;
        this.componentRef.instance.isClose = this.isClose;
        this.componentRef.instance.index = this.componentIndex;
        this.componentIndex++;
        this.componentRef.instance.afterAction.subscribe(e => {
          console.log("handle after action", e);
          this.missionDatas = e.mission_datas;
          this.missionDatas = this.missionDatas.filter(function (el) {
            return el.canceled_id == null;
          });
          this.handleTabSelect(this.selected - 1);
        });
        this.componentRef.instance.deleteSelf.subscribe(e => {
          this.target.remove(e);
          this.id--;
        });
      })
    }
  }

  afterCloseFolder(event) {
    console.log("handle data ", event);
    if (event.data) {
      this.isClose = event.data.mission_datas ? this.getMissionDataWithId(event.data.mission_datas, this.missionDataId) ? this.getMissionDataWithId(event.data.mission_datas, this.missionDataId).is_closed == 1 : false : false;
      this.isClosed.emit(this.isClose);
    }
  }


  getMissionDataWithId(missionDatas, id: string) {
    let missionData;
    if (Array.isArray(missionDatas)) {
      missionDatas.forEach(element => {
        if (element.id == id) {
          missionData = element;
        }
      })
    }
    return missionData;
  }


  createFormSurgery(surgery: any) {
    if (surgery) {
      return new FormSurgery(surgery.type, this.convert(surgery.date), surgery.age, surgery.complication, surgery.add_surgery_needed, surgery.comments, 
      surgery.mission_datas_id, surgery.id, this.patientId, surgery.photo, surgery.care_needed_id, surgery.surgery_needed_id);
    }
    return new FormSurgery();
  }

  convert(str) {
    if (str) {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    return str;
  }


  getId(id: number) {
    if (id < 10) {
      return "00" + id;
    }
    if (id >= 10 && id < 100) {
      return "0" + id;
    }
    else return id;
  }


}
